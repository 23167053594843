<template>
  <div>
    <!--Loading-->

    <AppSpinnerBig :loading="loading || waiting" />

    <div v-if="!showReasonForTransfer && !showMobileWalletProvidersList && !loading" :class="blur">
      <!--No Recipients-->

      <NoRecipients
        v-if="!hasRecipients && !loading"
        is-send-money
        @addRecipient="addRecipient"
        @back="$router.go(-1)"
      />

      <!--Recipients-->

      <AppCard
        :class="{
          'recipients-hidden': loading || !hasRecipients,
        }"
      >
        <template #header>
          <XeStepperCardHeader
            :title="$t('PageSendMoneyRecipient.Header').value"
            analytics-name="xe-send-money-recipient"
            :emit-back="true"
            @back="backToSendMoneyAmount"
          />
        </template>

        <p
          v-if="hasRecipients"
          class="card-warning"
          v-html="$t('PageSendMoneyRecipient.WarningMessage', { currency }).value"
        />

        <XeAddListItem
          :select="addRecipient"
          :text="$t('AddRecipient.AddRecipientText').value"
          data-testid="xe-send-money-add-recipient"
        />

        <XeRecipients
          ref="xeRecipientsList"
          :currency="currency"
          :country="country"
          :delivery-method="deliveryMethod"
          :show-loader="false"
          @viewRecipient="selectRecipient($event)"
          @add="addRecipient"
          @loaded="loadedRecipients($event)"
        />
      </AppCard>
    </div>

    <AppCard v-else-if="showReasonForTransfer" :class="blur">
      <template #header>
        <XeStepperCardHeader
          :title="$t('SendMoneyReasonforTransfer.WeNeedToAskText').value"
          :emit-back="true"
          analytics-name="xe-send-money-reason-for-transfer"
          @back="backToRecipients"
        >
          <template #left>
            <AppBackButton class="back-button" name="Go Back" icon="<" @click="backToRecipients" />
          </template>
        </XeStepperCardHeader>
      </template>

      <p class="mb-4 xs:mt-4 sm:mt-0 text-secondary-text text-base">
        {{ $t('SendMoneyReasonforTransfer.LegalRequirementText').value }}
      </p>

      <SendMoneyReasonForTransfer
        :country="selectedRecipient.country"
        :key="recipientName"
        :recipient-name="recipientName"
      />

      <template #footer>
        <AppCardFooter>
          <AppButton
            class="full-width-button"
            analytics-name="xe-send-money-reason-transfer-continue"
            :disabled="!paymentReferenceStore.getIsPaymentReferenceFormValid"
            :loading="loading"
            @click="onReasonForTransferContinue"
          >
            {{ $t('SendMoneyReasonforTransfer.ContinueText').value }}
          </AppButton>
        </AppCardFooter>
      </template>
    </AppCard>

    <SendMoneyWalletProviders
      v-if="showMobileWalletProvidersList"
      @back="backToReasonForTransfer"
      @selectProvider="onSelectMobileWalletProvider($event)"
      @recipient-edit="() => recipientNameMismatchCallback()"
    />

    <!-- Add Recipient Type Modal -->

    <AddRecipientTypeModal
      v-model="showAddRecipientType"
      :class="blurModal"
      is-send-money
      @addMyself="addMyself"
      @addSomeoneElse="addSomeoneElse"
      @addBusiness="addBusiness"
    />

    <!-- Add Recipient Modal -->

    <ModifyRecipientModal
      v-if="showAddRecipient"
      v-model="showAddRecipient"
      :class="blurModal"
      :myself="recipientMyself"
      :business="recipientBusiness"
      is-send-money
      confirm-text="Create"
      @success="onSuccessfulAddRecipient($event)"
      @back="backFromAddRecipient"
    />

    <ModifyRecipientModal
      v-if="showModifyRecipient"
      v-model="showModifyRecipient"
      :class="blurModal"
      :myself="selectedRecipient.isOwnAccount"
      :business="selectedRecipient.isBusinessAccount"
      :recipient="selectedRecipient"
      missing-information
      is-send-money
      confirm-text="Edit"
      @success="onSuccessfulAddRecipient($event)"
      @back="backFromAddRecipient"
    />

    <!-- Add Cash Recipient Modal -->

    <ModifyCashRecipientModal
      v-model="showAddCashRecipient"
      :key="showAddCashRecipient"
      :class="blurModal"
      :myself="recipientMyself"
      is-send-money
      confirm-text="Create"
      class="margin-top-cash-modal"
      @success="onSuccessfulAddCashRecipient($event)"
      @back="backFromAddCashRecipient"
    />

    <EditRecipientDetailsModal
      v-if="showEditCashRecipient"
      v-model="showEditCashRecipient"
      :class="blurModal"
      :myself="recipientMyself"
      :recipient="selectedRecipient"
      is-send-money
      confirm-text="Create"
      class="margin-top-cash-modal"
      @success="($event) => onSuccessfulAddCashRecipient($event, true)"
      @back="backFromAddCashRecipient"
      @close="backFromAddCashRecipient"
    />

    <SendMoneyRecipientMissingInformationModal
      :class="blurModal"
      :missing-information="showMissingInformationModal"
      is-send-money
      @continue="editRecipientWithMissingInformation"
      @close="selectAnotherRecipient"
    />

    <EditMissingRecipientInformation
      v-if="showEditMissingRecipientInformation && selectedRecipient.id"
      :key="selectedRecipient.id"
      :recipient-validation-errors="recipientValidationErrors"
      :selected-recipient="selectedRecipient"
      is-send-money
      @success="finishRecipientSelectCallback"
      @reset="resetEditMissingInformation"
    />

    <ZarWarningModal
      v-model="showZarModal"
      :class="blurModal"
      :recipient="selectedRecipient"
      @close="zarModel.closeModel"
      @proceed="zarModel.onProceed"
    />

    <ATOServiceModal
      v-model="showATOModal"
      :class="blurModal"
      :selected-recipient="selectedRecipient"
      @close="closeATOModal"
    />

    <InformationAppModal
      icon="chart"
      class="review-rate-modal"
      :class="blurModal"
      :value="showReviewYourRate"
      @close="hideReviewYourRate"
      :on-show="onShowReviewRate"
    >
      <h1>{{ $t('SendMoneyRecipient.ReviewYourRateTitle').value }}</h1>

      <p v-html="$t('SendMoneyRecipient.ReviewYourRateDescriptionText').value"></p>

      <template #footer>
        <AppButton @click="continueReviewYourRate">
          {{ $t('SendMoneyRecipient.ButtonReviewYourRateContinue').value }}
        </AppButton>

        <AppButton theme="secondary" @click="cancelReviewYourRate">
          {{ $t('SendMoneyRecipient.ButtonReviewYourRateCancelTransfer').value }}
        </AppButton>
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { ref, computed, reactive, onBeforeMount, nextTick, watch } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { storeToRefs } from 'pinia'
import XeRecipients from '@galileo/components/XeRecipients/XeRecipients'
import NoRecipients from '@galileo/components/Views/Recipient/NoRecipients'
import ModifyRecipientModal from '@galileo/components/Views/Recipient/ModifyRecipientModal'
import ModifyCashRecipientModal from '@galileo/components/Views/Recipient/ModifyCashRecipientModal'
import AddRecipientTypeModal from '@galileo/components/Views/Recipient/AddRecipientTypeModal'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import SendMoneyReasonForTransfer from '@galileo/components/Views/SendMoney/SendMoneyReasonForTransfer/SendMoneyReasonForTransfer'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { DeliveryMethod, PaymentMethod } from '@galileo/models/Transaction/app'

import XeAddListItem from '@galileo/components/XeAddListItem/XeAddListItem'

import SendMoneyRecipientMissingInformationModal from '@galileo/components/Views/SendMoney/SendMoneyRecipientMissingInformationModal'

import ZarWarningModal from '@galileo/components/Views/Recipient/ZARWarningModal/ZarWarningModal'
import ATOServiceModal from '@galileo/components/Views/Recipient/ATOService/ATOServiceModal'

import EditMissingRecipientInformation from '@galileo/components/Views/Recipient/EditMissingRecipientInformation'
import SendMoneyWalletProviders from '@galileo/components/Views/SendMoney/SendMoneyWalletProviders.vue'

import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_RECIPIENT_ORIGIN,
  SEGMENT_RECIPIENT_TYPE,
  SEGMENT_RECIPIENT_GLOBAL_NAME,
} from '@galileo/constants/segmentAnalytics'

import { getSegmentPayoutMethod } from '@galileo/composables/useSegmentHelpers'
import { useRecipientValidation } from '@galileo/composables/useRecipientHelpers'
import EditRecipientDetailsModal from '@galileo/components/Views/Recipient/EditRecipientDetailsModal'

import {
  useAlert,
  useMediaQuery,
  useTransition,
  AppCard,
  AppSpinnerBig,
  AppCardFooter,
  AppButton,
  AppLink,
  AppBackButton,
} from '@oen.web.vue2/ui'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import {
  useSendMoneyStore,
  useCountriesStore,
  useAppStore,
  useAnalyticsStore,
  useI18nStore,
  useAuthStore,
  useRecipientsStore,
  usePaymentReferenceStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyRecipient',
  components: {
    AppCard,
    AppSpinnerBig,
    XeRecipients,
    ModifyRecipientModal,
    ModifyCashRecipientModal,
    AddRecipientTypeModal,
    NoRecipients,
    XeStepperCardHeader,
    AppCardFooter,
    AppButton,
    AppLink,
    SendMoneyReasonForTransfer,
    XeAddListItem,
    SendMoneyRecipientMissingInformationModal,
    ZarWarningModal,
    ATOServiceModal,
    InformationAppModal,
    AppBackButton,
    EditMissingRecipientInformation,
    SendMoneyWalletProviders,
    EditRecipientDetailsModal,
  },

  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const countriesStore = useCountriesStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()
    const { isCorporateAccount } = storeToRefs(authStore)
    const paymentReferenceStore = usePaymentReferenceStore()

    const isO2OTransfer = computed(() => sendMoneyStore.isO2OContract)

    sendMoneyStore.setActiveStep(router.currentRoute.path)

    const xeRecipientsList = ref()
    const loading = ref(true)
    const waiting = ref(false)
    const blur = useTransition('blur', waiting)
    const blurModal = useTransition('blur-modal', waiting)
    const showReasonForTransfer = ref(false)
    const showMobileWalletProvidersList = ref(false)

    const showModifyRecipient = ref(false)

    const {
      showMissingInformationModal,
      showEditMissingRecipientInformation,
      recipientValidationErrors,
      isRecipientValid,
      editRecipientWithMissingInformation,
      resetEditMissingInformation,
      closeMissingInformationModal: selectAnotherRecipient,
    } = useRecipientValidation()

    const recipients = ref(null)
    const hasRecipient = ref(false)
    const country = ref(sendMoneyStore.getCountryTo)
    const currency = sendMoneyStore.form.currencyTo
    const amountTo = sendMoneyStore.getAmountTo(false)
    const deliveryMethod = sendMoneyStore.form.deliveryMethod

    const loadedRecipients = (result) => {
      recipients.value = result

      if (!hasRecipient.value && !hasRecipients.value) {
        nextTick(async () => {
          await addRecipient()
        })
      }

      loading.value = false
    }

    const hasRecipients = computed(() => {
      let recipientList = recipients.value
      if (recipients.value) {
        if (isCorporateAccount.value) {
          recipientList = recipients.value.filter((recipient) => !recipient.rawData.isGlobal)
        }
      }

      return recipients.value && recipientList.length > 0
    })

    const zarModel = reactive({
      closeModel: () => {},
      onProceed: () => {},
    })
    //Select Recipient Modal
    const selectedRecipient = ref({})

    const showATOModal = ref(false)
    const closeATOModal = () => {
      showATOModal.value = false
    }

    const showZarModal = ref(false)

    const selectRecipient = async (
      recipient,
      condition = SEGMENT_RECIPIENT_ORIGIN.EXISTING,
      goToReasonForTransfer = true //is false only when we create a new recipient
    ) => {
      resetEditMissingInformation()
      paymentReferenceStore.refreshPaymentReferenceForm()
      // global recipient easiest workflow
      if (recipient?.rawData.isGlobal) {
        selectedRecipient.value = recipient
        goToReasonForTransfer = false
        showReasonForTransfer.value = false
        showATOModal.value = true
      } else {
        waiting.value = true
        if (!goToReasonForTransfer) {
          selectedRecipient.value = null
        }
        const isValid = await isRecipientValid(recipient)
        waiting.value = false
        selectedRecipient.value = recipient
        if (isValid && recipientValidationErrors.value.length === 0) {
          if (goToReasonForTransfer) {
            paymentReferenceStore.resetPaymentReferenceForm()
          }

          //this is the end of flow either go to show reason for trasnfer
          //or go to payment if we just created a new recipient
          const finishRecipientSelectCallback = () => {
            showZarModal.value = false
            if (goToReasonForTransfer) {
              showReasonForTransfer.value = true
            } else {
              // that means we just created a new recipient
              onSuccessfullySelectedRecipient()
            }
          }

          if (
            recipient.country === 'ZA' &&
            recipient.currency === 'ZAR' &&
            !recipient.riaAlertAccepted
          ) {
            //setting up the ZAR interceptor
            zarModel.closeModel = async () => {
              showZarModal.value = false
              if (!goToReasonForTransfer) {
                await xeRecipientsList.value.updateList()
              }
              selectedRecipient.value = null
            }
            //zarModal as interceptor will call this function if it doesn't meet the requirement to show
            //or when the user aknowledged the modal
            zarModel.onProceed = finishRecipientSelectCallback
            showZarModal.value = true
          } else {
            finishRecipientSelectCallback()
          }
        }
      }

      // Global Recipient Selected (which gets logged as well as Recipient Selected)
      if (!recipient.isOwnAccount && recipient.rawData.isGlobal) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.GLOBAL_RECIPIENT_STARTED,
          traits: {
            globalRecipientName: SEGMENT_RECIPIENT_GLOBAL_NAME.ATO,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
          },
        })
      }

      //Prep segment event

      //[TODO] get rid of magic strings
      const recipientPayoutMethod =
        recipient.deliveryMethod === 'MobileWallet' ? 'Wallet' : recipient.deliveryMethod

      let recipientType = null

      if (recipient.isOwnAccount) {
        recipientType = SEGMENT_RECIPIENT_TYPE.SELF
      } else if (recipient.isBusinessAccount) {
        recipientType = SEGMENT_RECIPIENT_TYPE.BUSINESS
      } else {
        recipientType = SEGMENT_RECIPIENT_TYPE.INDIVIDUAL
      }
      if (!showMissingInformationModal.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_SELECTED,
          traits: {
            // location: SEGMENT_LOCATIONS.SEND_MONEY,
            condition: condition,
            globalRecipientName: recipient.rawData.isGlobal
              ? SEGMENT_RECIPIENT_GLOBAL_NAME.ATO
              : SEGMENT_RECIPIENT_GLOBAL_NAME.NONE,
            recipientPayoutMethod: recipientPayoutMethod,
            recipientId: recipient.id,
            recipientType: recipientType,
          },
        })
      }
    }

    // Add Recipient Modal
    const showAddRecipientType = ref(false)
    const showAddRecipient = ref(false)
    const showAddCashRecipient = ref(false)
    const showEditCashRecipient = ref(false)

    const addRecipient = async () => {
      if (
        deliveryMethod === DeliveryMethod.CashPickup.enumName ||
        deliveryMethod === 'MobileWallet'
      ) {
        showAddCashRecipient.value = true
      } else {
        showAddRecipientType.value = true
      }
      analyticsStore.track({
        event: SEGMENT_EVENTS.RECIPIENT_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          recipientPaymentMethod: getSegmentPayoutMethod(deliveryMethod),
        },
      })
    }

    const recipientMyself = ref(false)
    const recipientBusiness = ref(false)

    const addMyself = async () => {
      recipientBusiness.value = false

      if (isCorporateAccount.value) {
        recipientBusiness.value = true
      }
      recipientMyself.value = true
      showAddRecipientType.value = false
      showAddRecipient.value = true
    }

    const addSomeoneElse = async () => {
      recipientMyself.value = false
      recipientBusiness.value = false
      showAddRecipientType.value = false
      showAddRecipient.value = true
    }

    const addBusiness = async () => {
      recipientMyself.value = false
      recipientBusiness.value = true
      showAddRecipientType.value = false
      showAddRecipient.value = true
    }

    const fixOverflowForModals = () => {
      document.body.classList.remove('overflow-hidden')
    }

    const backFromAddRecipient = (isClose = false) => {
      showAddRecipientType.value = !showModifyRecipient.value && !isClose
      showModifyRecipient.value = false
      showAddRecipient.value = false
      paymentReferenceStore.resetPaymentReferenceForm()
      fixOverflowForModals()
    }

    const backFromAddCashRecipient = () => {
      showAddCashRecipient.value = false
      showEditCashRecipient.value = false
    }

    const onSuccessfulAddCashRecipient = async (newRecipient, notify = false) => {
      if (notify) {
        const { add: showSnackAlert } = useAlert()
        showSnackAlert($t('SendMoneyRecipient.NotifyRecipientEdited').value)
      }
      showAddCashRecipient.value = false
      paymentReferenceStore.refreshPaymentReferenceForm()
      paymentReferenceStore.setIsReasonForTransferLocked(true)
      await selectRecipient(newRecipient, SEGMENT_RECIPIENT_ORIGIN.NEW)
      loading.value = false
    }

    const onSuccessfulAddRecipient = async (newRecipient) => {
      paymentReferenceStore.refreshPaymentReferenceForm()
      paymentReferenceStore.setIsReasonForTransferLocked(true)

      showAddRecipient.value = false
      showModifyRecipient.value = false
      await selectRecipient(newRecipient, SEGMENT_RECIPIENT_ORIGIN.NEW, false)
    }

    let liquidityManager = sendMoneyStore.getLiquidityManager
    const onSuccessfullySelectedRecipient = async () => {
      waiting.value = true
      if (isO2OTransfer.value) {
        paymentReferenceStore.updateSMFormWithPaymentReference()

        //since we selected the recipient we can set the countryTo now
        sendMoneyStore.form.countryTo = selectedRecipient.value.country
        //set the recipient to the transaction
        sendMoneyStore.form.recipient = selectedRecipient.value

        //use transfer api endpoint to set the reason for transfer and the recipient
        await sendMoneyStore.addRecipientO2OTransfer()

        //go to summary afterwards
        waiting.value = false
        router.push('/send-money/summary')
        return
      }

      //set the recipient
      // set the recipient later otherwise it can cause confusion in the logic (in onBeforeMount)
      sendMoneyStore.form.recipient = selectedRecipient.value

      //store Reason for transfer data
      paymentReferenceStore.updateSMFormWithPaymentReference()
      const paymentMethod = sendMoneyStore.form.paymentMethod

      if (
        deliveryMethod === DeliveryMethod.CashPickup.enumName ||
        //BankTransfer at this point does not have a setlmentMethodId so the backend in this case throw an error, we skip this case too
        paymentMethod === PaymentMethod.BankTransfer.enumName ||
        paymentMethod === PaymentMethod.Interac.enumName
      ) {
        sendMoneyStore.goToNextStep()
      } else {
        // recalculate to check that the liquidity manager has changed (doesn't make sense for cashpickup since it's always RIA)
        try {
          await sendMoneyStore.calculate({ summary: true })

          if (sendMoneyStore.getLiquidityManager !== liquidityManager) {
            showReviewYourRate.value = true
            analyticsStore.track({
              event: SEGMENT_EVENTS.LIQUIDITY_MANAGER_CHANGED,
              traits: {
                oldLiquidityManager: liquidityManager,
                newLiquidityManager: sendMoneyStore.getLiquidityManager,
              },
            })
          } else {
            sendMoneyStore.goToNextStep()
          }
        } catch (ex) {
          appStore.logException('Exception during selecting recipient', ex)
          if (ex?.errorKey !== 'QUOTE070') {
            // If the error is quote070 we already catch this error
            appStore.messageBoxGenericError()
          }
        }
      }
      waiting.value = false
    }

    const backToRecipients = async () => {
      showReasonForTransfer.value = false

      //using the next tixk so the xeRecipientList will be rendered and the ref will be binded to the element
      nextTick(async () => {
        loading.value = true
        //update the list so if we accept ZAR warning it will refresh the recipients once again with the latest flag
        if (xeRecipientsList.value) {
          await xeRecipientsList.value.updateList()
        }
        loading.value = false
      })
    }

    const onReasonForTransferContinue = () => {
      if (deliveryMethod === 'MobileWallet') {
        paymentReferenceStore.updateSMFormWithPaymentReference()
        sendMoneyStore.form.recipient = selectedRecipient.value
        showReasonForTransfer.value = false
        showMobileWalletProvidersList.value = true
      } else {
        onSuccessfullySelectedRecipient()
      }
    }

    const backToReasonForTransfer = async () => {
      showReasonForTransfer.value = true
      showMobileWalletProvidersList.value = false
    }

    const onSelectMobileWalletProvider = (provider) => {
      const mobileWalletProviders = sendMoneyStore.mobileWalletProviders.providers
      const providerCount = mobileWalletProviders.length

      analyticsStore.track({
        event: SEGMENT_EVENTS.WALLET_PROVIDER_SELECTED,
        traits: {
          destinationCountry: country.value,
          walletProvider: provider.name,
          walletProviderCount: providerCount,
        },
      })
    }

    onBeforeMount(async () => {
      loading.value = true
      hasRecipient.value = false
      let recipient = sendMoneyStore.getRecipient

      if (recipient) {
        let recipients = recipientsStore.recipients
        if (recipients?.length < 1) {
          await sendMoneyStore.calculatorGetRecipients()
          recipients = recipientsStore.recipients
        }

        if (recipients) {
          recipient = recipients.find((item) => item.id === recipient.id)
          if (recipient) {
            selectedRecipient.value = recipient
            hasRecipient.value = true
          }
        }
      }

      // Skip this step if recipient is already set in store
      if (
        hasRecipient.value &&
        !sendMoneyStore.stepDirectionForward &&
        deliveryMethod !== 'MobileWallet'
      ) {
        if (!recipient.rawData.isGlobal) {
          showReasonForTransfer.value = true
        }
      } else if (
        hasRecipient.value &&
        (sendMoneyStore.form.isFromRecipient || sendMoneyStore.form.sendAgain)
      ) {
        selectRecipient(recipient)
      } else if (hasRecipient.value && deliveryMethod === 'MobileWallet') {
        showMobileWalletProvidersList.value = true
      } else {
        await countriesStore.fetchOfferedCountries()
      }

      analyticsStore.track({
        event: SEGMENT_EVENTS.RECIPIENT_ACCESSED,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
      loading.value = false
      //if we got here and the recipient was from recipient page we reset it
      sendMoneyStore.form.isFromRecipient = false
    })

    const showReviewYourRate = ref(false)
    const hideReviewYourRate = () => {
      showReviewYourRate.value = false
    }
    const continueReviewYourRate = () => {
      const oldLiquidityManager = liquidityManager
      liquidityManager = sendMoneyStore.getLiquidityManager
      analyticsStore.track({
        event: SEGMENT_EVENTS.LIQUIDITY_MANAGER_CHANGE_ACCEPTED,
        traits: {
          oldLiquidityManager,
          newLiquidityManager: liquidityManager,
        },
      })
      sendMoneyStore.goToNextStep()
    }
    const cancelReviewYourRate = () => {
      showReviewYourRate.value = false
      router.pop()
      analyticsStore.track({
        event: SEGMENT_EVENTS.LIQUIDITY_MANAGER_CHANGE_DECLINED,
      })
    }

    const onShowReviewRate = () => {
      const helpdeskLink = document.getElementById('review-rate-helpdesk-url')
      helpdeskLink.addEventListener('click', async () => {
        await analyticsStore.track({
          event: SEGMENT_EVENTS.LIQUIDITY_MANAGER_CHANGE_HELP_CLICKED,
        })

        setTimeout(async () => {
          await appStore.openHelpDesk({
            loadingRef: ref(false),
            constName: HelpDeskProperties.WHY_HAS_MY_SEND_RATE_CHANGED,
            article: HelpDesk[HelpDeskProperties.WHY_HAS_MY_SEND_RATE_CHANGED],
          })
        }, 150)
      })
    }

    const backToSendMoneyAmount = () => {
      router.replace('/send-money')
    }

    const getRecipientDetails = async () => {
      let recipientId = selectedRecipient.value.id

      if (selectedRecipient.value.isBankAccount) {
        selectedRecipient.value = await recipientsStore.getRecipient(recipientId)
      }
    }

    const finishRecipientSelectCallback = async (goToReasonForTransfer = true) => {
      if (goToReasonForTransfer) {
        await getRecipientDetails()
        showReasonForTransfer.value = true
      } else {
        // that means we just created a new recipient
        onSuccessfullySelectedRecipient()
      }
    }

    const recipientNameMismatchCallback = () => {
      showReasonForTransfer.value = false
      showMobileWalletProvidersList.value = false
      showEditCashRecipient.value = true
    }

    // TEMPORARY maybe better place ! PRECACHING
    if (deliveryMethod === 'MobileWallet') {
      sendMoneyStore.setMobileWalletProviders({
        country: sendMoneyStore.getCountryTo,
        currency,
        amount: amountTo,
      })
    }

    const recipientName = computed(() => {
      let accountName = selectedRecipient.value.fullName
      if (selectedRecipient.value.accountName) {
        accountName = selectedRecipient.value.accountName
      } else if (selectedRecipient.value.nickName || !accountName) {
        accountName = `${selectedRecipient.value.firstName} ${selectedRecipient.value.lastName}`
      }
      return accountName ?? ''
    })

    // Return
    return {
      mq: useMediaQuery(),
      country,
      currency,
      deliveryMethod,
      showAddRecipientType,
      addMyself,
      addSomeoneElse,
      addBusiness,
      addRecipient,
      onSuccessfulAddRecipient,
      onSuccessfulAddCashRecipient,
      selectRecipient,
      selectedRecipient,
      showAddRecipient,
      showAddCashRecipient,
      showEditMissingRecipientInformation,
      recipientValidationErrors,
      blur,
      blurModal,
      waiting,
      loading,
      loadedRecipients,
      hasRecipients,
      recipientMyself,
      recipientBusiness,
      showReasonForTransfer,
      showMobileWalletProvidersList,
      backFromAddRecipient,
      backFromAddCashRecipient,
      onSuccessfullySelectedRecipient,
      backToRecipients,
      backToReasonForTransfer,
      onReasonForTransferContinue,
      onSelectMobileWalletProvider,
      paymentReferenceStore,

      $t,
      showModifyRecipient,
      showMissingInformationModal,
      selectAnotherRecipient,
      editRecipientWithMissingInformation,
      zarModel,
      showZarModal,
      xeRecipientsList,
      showATOModal,
      closeATOModal,
      showReviewYourRate,
      hideReviewYourRate,
      continueReviewYourRate,
      cancelReviewYourRate,
      backToSendMoneyAmount,
      isO2OTransfer,
      resetEditMissingInformation,
      finishRecipientSelectCallback,
      recipientNameMismatchCallback,
      showEditCashRecipient,
      recipientName,
      onShowReviewRate,
    }
  },
}
</script>

<style scoped>
.recipients-hidden {
  @apply hidden;
}
.recipient-add {
  @apply flex items-center w-full;
}

.card-warning {
  @apply mb-4 py-2 px-3 text-sm rounded-md leading-5;
  background-color: #fff5e3;
  color: #ca542d;
}

::v-deep .margin-top-cash-modal > .overlay-content > .card--modal {
  @screen md {
    margin-top: 120px;
  }
}
.full-width-button {
  @apply w-full !important;
}

.review-rate-modal {
  ::v-deep .card-content .card-content-block {
    @apply mb-8;
  }
}
</style>
