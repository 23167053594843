import { ref } from '@vue/composition-api'

export default function () {
  const shouldTrim = ref(false)

  const checkProvider = (provider) => {
    let walletNames = walletsWOLeadingZero[provider.country]
    if (walletNames) {
      shouldTrim.value = walletNames.includes(provider.name)
    } else {
      shouldTrim.value = false
    }
  }

  const getCorrectedFields = (filledFields) => {
    if (shouldTrim.value) {
      let mwNumber = filledFields['mobileWalletAccountNo']

      //if the number starts with 0
      if (mwNumber[0] === '0') {
        filledFields['mobileWalletAccountNo'] = mwNumber.substring(1)
      }
    }

    return filledFields
  }

  return { checkProvider, getCorrectedFields }
}

const walletsWOLeadingZero = {
  //countryCode:['WalletName']
  PH: ['GCash'],
  GH: ['MTN MoMo', 'Vodafone', 'AirtelTigo'],
  CD: ['Airtel Money'],
  CM: ['Orange Money'],
}
