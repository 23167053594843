import { ref } from '@vue/composition-api'

import { useSendMoneyStore, useI18nStore } from '@galileo/stores'

export default function (router, recipientEditCallback) {
  const errorTitle = ref(``)
  const errorDescription = ref(``)

  const callback = ref(() => {})
  const sendMoneyStore = useSendMoneyStore()

  const mobileWallet = sendMoneyStore.mobileWalletProvider
  const recipient = sendMoneyStore.getRecipient

  const i18nStore = useI18nStore()

  const goToSendMoneyAmountCallback = () => {
    let nextPage = '/send-money'
    router.replace(nextPage)
  }

  const parseErrorType = (errorType) => {
    switch (errorType) {
      case 101:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.AccountNotFound.Title', {
          walletName: mobileWallet.name,
        }).value
        //`This {{walletName}} wallet was not found`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.AccountNotFound.Description'
        ).value
        //`We can’t find an account with these details. Double check the information or try another delivery option.`
        callback.value = () => {}
        break
      case 103:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.AccountKYC.Title', {
          walletName: mobileWallet.name,
        }).value
        //`This {{walletName}}  wallet isn’t set up`
        errorDescription.value = i18nStore.$t('UseMobieWalletsWarning.AccountKYC.Description', {
          walletName: mobileWallet.name,
          recipientName: recipient.firstName,
        }).value
        //`Ask {{recipientName}} to verify their {{walletName}}  wallet before you try again, or choose a different delivery option.`
        //redirect to quote
        callback.value = goToSendMoneyAmountCallback
        break
      case 102:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.AccountLocked.Title', {
          walletName: mobileWallet.name,
        }).value
        //`The account for this {{walletName}} wallet is locked or suspended`
        errorDescription.value = i18nStore.$t('UseMobieWalletsWarning.AccountLocked.Description', {
          walletName: mobileWallet.name,
        }).value
        //`Please choose a different delivery option.`
        callback.value = goToSendMoneyAmountCallback
        //quote
        break

      case 118:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.BalanceLimit.Title', {
          walletName: mobileWallet.name,
        }).value
        //`This {{walletName}} wallet is full`
        errorDescription.value = i18nStore.$t('UseMobieWalletsWarning.BalanceLimit.Description', {
          walletName: mobileWallet.name,
        }).value
        //`This {{walletName}} wallet can't receive any more money at this time. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 117:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.DailyAmountLimit.Title').value
        //`This wallet has reached its transaction limit `
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.DailyAmountLimit.Description'
        ).value
        //`We can't send this transfer because the wallet has reached its daily transaction limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 116:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.DailyCountLimit.Title').value
        //`This wallet can’t receive any more transfers`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.DailyCountLimit.Description',
          {
            walletName: mobileWallet.name,
          }
        ).value
        //`This {{walletName}} wallet has reached its daily transaction count limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 115:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.MonthlyAmountLimit.Title').value
        //`This wallet has reached its transaction limit `
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.MonthlyAmountLimit.Description'
        ).value
        //`We can't send this transfer because the wallet has reached its monthly transaction limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 114:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.MonthlyCountLimit.Title').value
        //`This wallet can’t receive any more transfers`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.MonthlyCountLimit.Description',
          {
            walletName: mobileWallet.name,
          }
        ).value
        //`This {{walletName}} wallet has reached its monthly transaction count limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 113:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.AnnualAmountLimit.Title').value
        //`This wallet has reached its transaction limit `
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.AnnualAmountLimit.Description'
        ).value
        //`We can't send this transfer because the wallet has reached its annual transaction limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 111:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.TransactionAmountLimit.Title').value
        //`This wallet has reached its transaction limit `
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.TransactionAmountLimit.Description'
        ).value
        //`We can't send this transfer because the wallet has reached its transaction limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 112:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.AnnualCountLimit.Title').value
        //`This wallet can’t receive any more transfers`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.AnnualCountLimit.Description',
          {
            walletName: mobileWallet.name,
          }
        ).value
        //`This {{walletName}} wallet has reached its yearly transaction count limit. You can still send money by changing the delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 110:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.ReceiverTransactionLimit.Title', {
          walletName: mobileWallet.name,
        }).value
        //`This {{walletName}} wallet has reached its transaction limit`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.ReceiverTransactionLimit.Description'
        ).value
        //`Please choose a different delivery option.`
        callback.value = goToSendMoneyAmountCallback
        break
      case 104:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.ReceiverMismatch.Title', {
          walletName: mobileWallet.name,
        }).value
        //`{{walletName}} wallet info doesn’t match`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.ReceiverMismatch.Description',
          {
            walletName: mobileWallet.name,
            recipientName: recipient.firstName,
          }
        ).value
        //`Confirm the account name registered to {{recipientName}}'s wallet and check that the name you entered matches the name on their wallet account.`
        callback.value = recipientEditCallback
        break
      case 400:
      default:
        errorTitle.value = i18nStore.$t('UseMobieWalletsWarning.SomethingWentWrong.Title').value
        //`Oops, something went wrong`
        errorDescription.value = i18nStore.$t(
          'UseMobieWalletsWarning.SomethingWentWrong.Description'
        ).value
        //`Please try again later`
        callback.value = goToSendMoneyAmountCallback
        break
    }
  }

  return { errorTitle, callback, parseErrorType, errorDescription }
}
