import { STREET_TYPES } from '@galileo/constants/streetTypes'
import { useAuthStore, useCountriesStore } from '@galileo/stores'

const authStore = useAuthStore()
const countriesStore = useCountriesStore()

async function getMappedStateFrieldsByUserCountry() {
  const states = await countriesStore.getStatesByCountryCode(authStore.user.country)
  const mappedStates = states.map((item) => {
    return {
      text: item.name,
      value: item.value,
    }
  })
  return mappedStates
}

export default ($t) => {
  const fieldRules = {
    socialSecurityNumber: {
      type: 'inputMask',
      mask: '###-##-####',
      inputmode: 'numeric',
      placeholder: '123-45-6789',
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.SocialSecurityNumberLabel').value,
      helperText: $t('SendMoneyAdditionalDetailsFormFieldParser.SocialSecurityNumberHelperText')
        .value, //'Social Security Number or Individual Taxpayer Identification Number',
    },
    sourceOfFunds: {
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.SourceOfFundsPlaceholder').value, //'Select source',
      label: ' ',
      group: 'SOURCE_OF_FUNDS',
    },
    sourceOfFundsFreeText: {
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.SourceOfFundsFreeTextPlaceholder')
        .value, //'Please specify',
      label: ' ',
      group: 'SOURCE_OF_FUNDS',
    },
    relationToBeneficiary: {
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.RelationToBeneficiaryPlaceholder')
        .value, //'Select relationship',
      label: ' ',
      type: 'optionList',
      group: 'RELATIONSHIP_FIELDS',
    },
    ria_bene_mobile: {
      label: ' ',
      group: 'PHONE_GROUP',
    },
    mobilePrefix: {
      label: 'Mobile phone prefix',
      group: 'CUSTOMER_PHONE_GROUP',
    },
    mobilePhone: {
      label: 'Mobile phone number',
      group: 'CUSTOMER_PHONE_GROUP',
    },
    ria_bene_addressLine1(formField) {
      let lengthRegexChunk = '{2,50}'
      const lengthValidationRegex = formField.validations.find(
        (validation) => validation.type === 'Regex'
      )
      const lengthRegexExtracted = lengthValidationRegex.value.match(/\{\d+(?:,\d+)?\}/)

      if (lengthRegexExtracted) {
        lengthRegexChunk = lengthRegexExtracted[0]
      }

      const newValidationRegex = `^(?:[a-zA-Z0-9,\\s\\(\\)\\+\\-'\\/\\?\\.:]${lengthRegexChunk})?$`
      let newValidations = formField.validations.filter((validation) => validation.type !== 'Regex')
      newValidations.push({ type: 'Regex', value: newValidationRegex })
      newValidations.push({ type: 'pobox' })

      return {
        label: $t('SendMoneyAdditionalDetailsFormFieldParser.ria_bene_addressLine1Label').value, //"Recipient's address",
        placeholder: $t(
          'SendMoneyAdditionalDetailsFormFieldParser.ria_bene_addressLine1Placeholder'
        ).value, // 'Enter recipient address',
        group: 'PHONE_GROUP',
        validations: newValidations,
      }
    },
    idType: {
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.IdTypePlaceholder').value, //'Select a type',
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.IdTypeLabel').value, //'Type of identification',
    },
    // ria_idType: {
    //   placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.IdTypePlaceholder').value, //'Select a type',
    //   label: $t('SendMoneyAdditionalDetailsFormFieldParser.IdTypeLabel').value, //'Type of identification',
    // },
    idExpiryDate: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.IdExpiryDateLabel').value, //'Expiration date',
      type: 'date',
    },
    // ria_idExpiryDate: {
    //   label: $t('SendMoneyAdditionalDetailsFormFieldParser.IdExpiryDateLabel').value, //'Expiration date',
    //   type: 'date',
    // },
    passportNumber: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.PassportNumberLabel').value, //'Passport number',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.PassportNumberPlaceholder').value, //'Enter number',
    },
    passportIssuer: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.PassportIssuerLabel').value, //'Issued by',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.PassportIssuerPlaceholder').value, //'Select a country',
    },
    licenceIssuer: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceIssuerLabel').value, //'Issuing state',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceIssuerPlaceholder').value, //'Issuing state',
      type: 'optionList',
      hasAsyncValues: true,
      getAsyncValues: async () => {
        return await getMappedStateFrieldsByUserCountry()
      },
    },
    licenceNumber: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceNumberLabel').value, //'License number',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceNumberPlaceholder').value, //'Enter number',
    },
    licenceVersion: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceVersionLabel').value, //'License version',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceVersionPlaceholder').value, //'e.g. 123',
      helperText: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceVersionHelperText').value,
    },
    licenceCardNumber: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceCardNumberLabel').value, //'License number',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceCardNumberPlaceholder')
        .value, //'Enter your card number',
    },
    passportFileNumber: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.PassportFileNumberLabel').value, //'Passport File number',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.PassportFileNumberPlaceholder')
        .value, //'Enter your Passport File number',
    },
    nationalIdIssuer: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.NationalIdIssuerLabel').value, //'National ID issuer',
      placeholder: $t('AdditionalInformationModal.IssuedByStatePlaceholder')
        .value, //'Enter ID issuer'
      type: 'optionList',
      hasAsyncValues: true,
      getAsyncValues: async () => {
        return await getMappedStateFrieldsByUserCountry()
      },
    },
    nationalIdNumber: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.NationalIdNumberLabel').value, //'National ID number',
      placeholder: $t('AdditionalInformationModal.EnterNumberPlaceholder')
        .value, //'National ID number',
    },
    idIssueDate: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.IdIssueDateLabel').value, //'Date of Issue',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.IdIssueDatePlaceholder').value, //'Enter date',
      type: 'date',
    },
    ria_bene_countryOfBirth: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.ria_BeneCountryOfBirthLabel').value, //'Recipient country of birth',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.ria_BeneCountryOfBirthPlaceholder')
        .value, //'Select country',
      type: 'country',
    },
    ria_bene_dateOfBirth: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.ria_bene_dateOfBirthLabel').value, //'Recipient date of birth',
      type: 'date',
    },
    dateOfBirth: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.dateOfBirth').value, //'Date of birth',
      shouldDisplayLabels: false,
      type: 'date',
    },
    ria_bene_nationality: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.ria_bene_nationalityLabel').value, //'Recipient nationality',
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.ria_bene_nationalityPlaceholder')
        .value, //'Select their nationality',
      type: 'nationality',
    },
    relationToBeneficiaryFreeText: {
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.RelationshipFreeTextPlaceholder')
        .value, //'Please specify',
      label: null,
      group: 'RELATIONSHIP_FIELDS',
    },
    countryOfBirth: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.CountryOfBirthLabel').value,
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.CountryOfBirthPlaceholder').value,
    },
    nationality: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.NationalityLabel').value,
      placeholder: $t('SendMoneyAdditionalDetailsFormFieldParser.NationalityPlaceholder').value,
      type: 'nationality',
    },
    addressLine1: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.addressLine1').value, //'Address',
      group: 'BASIC_ADDRESS_FIELDS',
    },
    place: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.townOrCity').value, // 'Town or city',
      group: 'BASIC_ADDRESS_FIELDS',
    },
    postalCode: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.postalCode').value, //'Postal code',
      group: 'BASIC_ADDRESS_FIELDS',
    },
    streetNumber: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.streetNumber').value, //'Street number',
      group: 'BASIC_ADDRESS_FIELDS',
    },
    streetName: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.streetName').value, //'Street name',
      group: 'BASIC_ADDRESS_FIELDS',
    },
    streetType: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.streetType').value, //'Street type',
      group: 'BASIC_ADDRESS_FIELDS',
      type: 'optionList',
      hasAsyncValues: true,
      getAsyncValues: async () => {
        const streetTypes = [...new Set(Object.values(STREET_TYPES))].map((item) => {
          return {
            text: item,
            value: item,
          }
        })
        return streetTypes
      },
    },
    state: {
      label: $t('SendMoneyAdditionalDetailsFormFieldParser.state').value, //'State',
      group: 'BASIC_ADDRESS_FIELDS',
      type: 'optionList',
      hasAsyncValues: true,
      getAsyncValues: async () => {
        return await getMappedStateFrieldsByUserCountry()
      },
    },
  }

  return { fieldRules }
}
