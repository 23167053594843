import { useI18nStore, useSendMoneyStore, useAuthStore } from '@galileo/stores'

export default () => {
  //dependat variables
  const authStore = useAuthStore()
  let occupationOtherField = null
  let sourceOfFundsOtherField = null
  let relationToBeneficiaryOtherField = null
  let idDocOtherFields = []
  const i18nStore = useI18nStore()
  const sendMoneyStore = useSendMoneyStore()

  //rules
  const formFieldRules = {
    occupationFreeText(formField) {
      occupationOtherField = formField
      formField.hidden = true
      return formField
    },
    occupation(formField) {   
      formField.hidden = true
      return formField
    },
    industry(formField) {    
      formField.hidden = true    
      return formField
    },
    subIndustry(formField) {    
      formField.hidden = true   
      return formField
    },
    sourceOfFundsFreeText(formField) {
      formField.hidden = true
      sourceOfFundsOtherField = formField
      return formField
    },
    sourceOfFunds(formField) {
      const activeStep = sendMoneyStore.activeStep
      if (activeStep.name === 'Recipient') {
        formField.props.placeholder = i18nStore.$t(
          'SendMoneyWalletDetails.SourceOfFundsPlaceholder'
        ).value
        formField.label = i18nStore.$t('SendMoneyWalletDetails.SourceOfFundsLabel').value
      }
      formField.listeners = {
        input: (value) => {
          sourceOfFundsOtherField.hidden = value !== 'OTH'
        },
      }
      return formField
    },
    relationToBeneficiaryFreeText(formField) {
      formField.hidden = true
      relationToBeneficiaryOtherField = formField
      return formField
    },
    relationToBeneficiary(formField) {
      const activeStep = sendMoneyStore.activeStep
      if (activeStep.name === 'Recipient') {
        formField.props.placeholder = i18nStore.$t(
          'SendMoneyWalletDetails.RelationshipPlaceholder'
        ).value
        formField.label = i18nStore.$t('SendMoneyWalletDetails.RelationshipLabel').value
      }
      formField.listeners = {
        input: (value) => {
          relationToBeneficiaryOtherField.hidden = value !== 'OTHER'
        },
      }
      return formField
    },
    ria_bene_mobile(formField) {
      formField.hidden = true
      return formField
    },
    mobilePrefix(formField) {
      formField.hidden = true
      return formField
    },
    mobilePhone(formField) {
      formField.hidden = true
      return formField
    },
    mobileWalletAccountNo(formField) {
      formField.label = i18nStore.$t('SendMoneyWalletDetails.MobileWalletLabel').value
      formField.props.placeholder = i18nStore.$t(
        'SendMoneyWalletDetails.MobileWalletPlaceholder'
      ).value

      return formField
    },
    riaQuestion_461255(formField) {
      formField.hidden = true
      return formField
    },
    idType(formField) {
      formField.listeners = {
        input: (value) => {
          idDocOtherFields.forEach((field) => {
            if (field.fieldDefinition.selectedId === value) {
              field.hidden = false
            } else {
              field.hidden = true
            }
          })
        },
      }
      return formField
    },
    licenceNumber(formField) {
      if (formField.fieldDefinition.selectedId === 'DriverLicence') {
        if (authStore?.user?.country === 'NZ') {
          formField.fieldDefinition.helperText = i18nStore.$t(
            'SendMoneyAdditionalDetailsFormFieldParser.LicenceNumberHelperText'
          ).value
          formField.props.helperText = formField.fieldDefinition.helperText
        }
        idDocOtherFields.push(formField)
        formField.hidden = true
        return formField
      }
      return null
    },
    licenceCardNumber(formField) {
      formField.hidden = true
      return formField
    },
    passportFileNumber(formField) {
      formField.hidden = true
      formField.validation[0].message = i18nStore.$t(
        'SendMoneyAdditionalDetailsFormFieldParser.PassportFileNumberValidationMsg'
      ).value
      return formField
    },
    default(formField) {
      if (
        formField.fieldDefinition.selectedId === 'Passport' ||
        formField.fieldDefinition.selectedId === 'DriverLicence' ||
        formField.fieldDefinition.selectedId === 'NationalId'
      ) {
        idDocOtherFields.push(formField)
        formField.hidden = true
        return formField
      }
      return null
    },
  }

  return {
    formFieldRules,
  }
}
