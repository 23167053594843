<template>
  <InformationAppModal
    :value="hasWalletError.hasError"
    icon="warningYellow"
    class="email-edit-alert"
  >
    <h1 class="title">{{ errorTitle }}</h1>
    <p v-html="errorDescription" />

    <template #footer>
      <AppButton @click="closeWarning()"> {{ $t('RateChangedModal.ButtonGotIt').value }}</AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'
import useMobileWalletsWarning from '@galileo/composables/useMobileWalletsWarning'
import { useRouter } from '@galileo/composables/useRouter'


import { useI18nStore, useSendMoneyStore } from '@galileo/stores'


export default defineComponent({
  components: { AppButton, InformationAppModal },
  props: {},
  setup(props, { emit }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const { errorTitle, callback, parseErrorType, errorDescription } = useMobileWalletsWarning(
      router,
      () => emit('recipient-edit')
    )
    const hasWalletError = computed(() => {
      const error = sendMoneyStore.mobileWalletWarning
      if (error.hasError) {
        parseErrorType(error.errorCode)
      }
      return error
    })

    const closeWarning = () => {
      hasWalletError.value.hasError = false
      callback.value()
    }
    return { hasWalletError, closeWarning, errorTitle, errorDescription, $t }
  },
})
</script>
