<template>
  <AppInputWrapper
    v-slot="{ input, label: wrapperLabel }"
    :class="{
      'input--no-border': !border,
      'input--no-arrow': !showArrow,
    }"
  >
    <PrimeDropdown
      v-model="input.model"
      class="input-input"
      :options="getOptions(options)"
      :option-label="selectedDisplayField"
      :option-value="selectedValueField"
      :data-key="dataKey"
      v-bind="input.attrs"
      :aria-labelled-by="wrapperLabel.id"
      :show-clear="showClear"
      v-on="input.listeners"
      :disabled="disableDropdown"
    >
      <!-- selected option slot -->
      <template #value="{ value: selectedValue, placeholder, option }">
        <!--{{ value }} {{ option }}-->
        <div class="selection-option" v-if="option">
          <slot name="selected" :value="selectedValue" :option="option">
            <!-- Slot content if not specified -->
            <div class="selected-value">
              <span>{{ optionPreText }}</span>
              <span v-if="option.value == 'OpenBanking' && isPayTo" class="selected-value-text">
                {{ $t('PageSendMoneyAmount.PayToOpenBankingDropdownText').value }}
              </span>
              <span class="selected-value-text" v-else-if="option.value == 'OpenBanking'">
                {{ $t('PageSendMoneyAmount.VoltOpenBankingDropdownText').value }}
              </span>
              <span class="selected-value-text" v-else>
                {{ option[selectedDisplayField || selectedValueField] }}
              </span>
            </div>
          </slot>
        </div>
        <span v-else class="select-placeholder">
          {{ placeholder }}
        </span>
      </template>

      <!-- dropdown slot -->
      <template #option="{ option }">
        <AppRipple
          :class="{
            'dropdown-option-disabled': !option.isAvailable,
          }"
        >
          <div class="dropdown-option" @click="clickOption(option, $event)">
            <div class="dropdown-option-container">
              <slot name="option" :option="option">
                <div class="selected-value">
                  <div>{{ optionPreText }}</div>
                  <div class="selected-value-text">
                    {{ option[selectedDisplayField || selectedValueField] }}
                  </div>
                </div>
              </slot>
            </div>
          </div>
          <hr v-if="option.separator" class="option-separator" />
        </AppRipple>
      </template>
    </PrimeDropdown>
  </AppInputWrapper>
</template>

<script>
import { AppInputWrapper, PrimeDropdown, AppRipple } from '@oen.web.vue2/ui'
import { computed, ref } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'
import useOpenBanking from '@galileo/composables/useOpenBanking'
export default {
  name: 'AppMethodDropdown',
  emits: ['input', 'loaded'],
  components: {
    AppInputWrapper,
    PrimeDropdown,
    AppRipple,
  },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedDisplayField: {
      type: String,
      default: '',
      required: true,
    },
    selectedValueField: {
      type: String,
      default: null,
      required: true,
    },
    dataKey: {
      type: String,
      default: 'index',
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    optionPreText: {
      type: String,
      required: true,
    },
    disableDropdown: {
      type: Boolean,
      default: false,
    },
    senderCountry: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    const openBanking = useOpenBanking()
    const isPayTo = computed(() => openBanking.isPayTo.value)

    let adjustedOptions = []
    let lastOptions = null
    const getOptions = () => {
      const options = props.options
      if (options !== lastOptions) {
        if (options && props.dataKey === 'index') {
          let i = 1
          adjustedOptions = options.map((option) => {
            if (!option.index) {
              option.index = option[props.selectedValueField] + '_' + i
            }
            i++
            return option
          })
        } else {
          adjustedOptions = options

          lastOptions = options
        }
      }

      return adjustedOptions
    }

    const clickOption = (option, ev) => {
      if (option && !option.isAvailable) {
        ev.preventDefault()
        ev.stopPropagation()
      }
    }

    emit('loaded', this)

    return {
      getOptions,
      clickOption,
      openBanking,
      isPayTo,
      $t,
    }
  },
}
</script>

<style scoped>
::v-deep .md-ripple-wave {
  @apply bg-gray-lighter;
  &.md-ripple-enter {
    @apply opacity-100;
  }
}

.option-separator {
  @apply m-0;
  @apply text-gray-light;
}

.select-placeholder {
  @apply text-tertiary-text;
}
.selection-option {
  @apply w-full;
}
.selected-value {
  @apply w-full;
}
.selected-value-text {
  @apply text-blue-text;
}

.input {
  &.input--no-border .input-input {
    @apply border-0;
    @apply shadow-none !important;
  }
  &.input--no-arrow ::v-deep .p-dropdown-trigger-icon {
    @apply hidden;
  }
}
.dropdown-option-disabled {
  background-color: white;
  cursor: default;

  .dropdown-option-container {
    opacity: 0.5;
  }
}
</style>
