<template>
  <div>
    <AppModal v-model="model" class="modal-card" title="">
      <template #header="{ dismiss }">
        <AppModalHeader v-if="!showNextPage" class="first-page-header">
          <template #default>
            <img
              v-if="!showNextPage"
              class="mb-6"
              src="@galileo/assets/images/illustrations/rocket2.svg"
              alt="rocket"
              width="40"
            />
          </template>
          <template #right>
            <XeBackButton
              theme="icon"
              icon="x"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              analytics-name="ATO-recipient-modal"
              @click="dismiss"
            />
          </template>
        </AppModalHeader>
        <AppModalHeader v-if="showNextPage" class="second-page-header">
          <template #left>
            <XeBackButton
              icon="<"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              analytics-name="ATO-recipient-modal"
              @click="backClick"
            />
          </template>
          <template #default>
            {{ $t('ComponentATOServiceModal.PaymentOfServiceText').value }}
          </template>
          <template #right>
            <XeBackButton
              icon="x"
              :name="$t('PageSendMoneyAdditionalDetails.GoBackTooltipText').value"
              analytics-name="ATO-recipient-modal"
              @click="dismiss"
            />
          </template>
        </AppModalHeader>
      </template>
      <div v-if="!showNextPage">
        <h1 class="ato-modal-title">{{ $t('ComponentATOServiceModal.PageOneTitle').value }}</h1>
        <p class="ato-modal-content" v-html="$t('ComponentATOServiceModal.PageOneText').value" />
      </div>
      <div v-if="showNextPage" class="payment-of-servece-page">
        <p>{{ $t('ComponentATOServiceModal.PageTwoText').value }}</p>
        <AppInputText
          v-model="paymentRefrenceNumber"
          :label="$t('ComponentATOServiceModal.PRNlabel').value"
          :placeholder="$t('ComponentATOServiceModal.PRNplaceholder').value"
          :validation="validation.PRN"
        />
        <AppLink class="modal-link" @click="openLink">
          {{ $t('ComponentATOServiceModal.LinkText').value }}
        </AppLink>
      </div>
      <template #footer>
        <AppModalFooter class="footer">
          <slot name="footer">
            <AppButton v-if="!showNextPage" @click="nextStep">
              {{ $t('ComponentATOServiceModal.ButtonContinueText').value }}
            </AppButton>
            <AppButton v-if="showNextPage" :disabled="validation.$anyInvalid" @click="validatePRN">
              {{ $t('ComponentATOServiceModal.ButtonContinueText').value }}
            </AppButton>
            <AppButton v-if="!showNextPage" theme="secondary" @click="closeAtoModal">
              {{ $t('ComponentATOServiceModal.ButtonChangeRecipientText').value }}
            </AppButton>
          </slot>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { required } from '@vuelidate/validators'
import { useRouter } from '@galileo/composables/useRouter'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import { useSendMoneyStore, useI18nStore, useAuthStore } from '@galileo/stores'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppInputText,
  AppButton,
  AppLink,
} from '@oen.web.vue2/ui'

export default {
  components: {
    AppModal,
    AppModalHeader,
    XeBackButton,
    AppModalFooter,
    AppButton,
    AppInputText,
    AppLink,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedRecipient: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const { model } = useVModel(props, emit)

    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()

    let showNextPage = ref(false)
    let paymentRefrenceNumber = ref('')

    const profileId = authStore.userProfile

    const validation = useValidation({
      PRN: {
        $value: ref(paymentRefrenceNumber),
        required: {
          $validator: required.$validator,
          $message: $t('ComponentATOServiceModal.InvalidNumberText').value,
        },
        validPRN: {
          $validator() {
            if (validation.PRN.required.$invalid && paymentRefrenceNumber.value !== '') {
              return false
            } else {
              return true
            }
          },
          $message: $t('ComponentATOServiceModal.InvalidNumberText').value,
        },
      },
    })

    const validatePRN = async () => {
      const response = await sendMoneyStore.validatePaymentReferenceNumber({
        profileId: profileId.customer.id,
        recipientId: props.selectedRecipient.id,
        paymentRefrenceNumber: paymentRefrenceNumber.value,
      })
      if (response) {
        sendMoneyStore.form.transferReason = 'PRIV_TAX'
        sendMoneyStore.form.transferReasonText = 'Tax'
        sendMoneyStore.form.transferReferenceText = paymentRefrenceNumber.value
        sendMoneyStore.form.recipient = props.selectedRecipient

        router.push('/send-money/payment')
      } else {
        validation.PRN.required.$invalid = true
      }
    }

    const openLink = () => {
      // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      const url = 'https://help.xe.com/hc/en/articles/4402897555089'
      window.open(url)
    }

    const closeAtoModal = () => {
      emit('close')
    }

    let nextStep = () => {
      showNextPage.value = true
    }

    const backClick = () => {
      showNextPage.value = false
    }

    return {
      model,
      showNextPage,
      paymentRefrenceNumber,
      validation,
      validatePRN,
      nextStep,
      closeAtoModal,
      backClick,
      openLink,
      $t,
    }
  },
}
</script>

<style scoped>
.modal-card {
  ::v-deep .overlay-content {
    .card {
      margin-bottom: 0;
    }
  }
}
.header {
  @apply items-start px-0 pt-0 pb-12;
}

.first-page-header {
  @apply flex items-start px-12 pt-12;
}

.second-page-header {
  @apply px-12 pt-12;
}

.ato-modal-title {
  @apply text-2xl font-medium text-center;
  @apply mb-4;
}

.ato-modal-content {
  @apply text-base text-center;
  ::v-deep .highlighted-text {
    color: #0a146e;
    font-weight: 500;
  }
}

.payment-of-servece-page {
  @apply flex flex-col gap-8;
  margin-bottom: 212px;
}

.footer {
  @apply flex flex-col-reverse gap-2;
}

.modal-link {
  @apply text-center;
}
</style>
