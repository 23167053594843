<template>
  <AppList class="recipient-list">
    <AppSpinnerBig v-if="showLoader" :loading="loading" :inline="true" />
    <template>
      <div v-if="ownRecipients && ownRecipients.length > 0">
        <h1 class="heading">
          {{ myAccountsCategoryTitle }}
        </h1>
        <SingleRecipient
          v-for="recipient in ownRecipients"
          :key="recipient.id"
          :recipient="recipient"
          :action-needed="actionNeeded"
          :select="viewRecipient"
          :countries="countries"
        />
      </div>

      <div v-if="allRecipients && allRecipients.length > 0" class="mt-8">
        <h1 class="heading">
          {{ otherRecipientsCategoryTitle }}
        </h1>
        <SingleRecipient
          v-for="recipient in allRecipients"
          :key="recipient.id"
          :recipient="recipient"
          :action-needed="actionNeeded"
          :select="viewRecipient"
          :countries="countries"
        />
      </div>

      <div v-if="mobileWalletRecipients && mobileWalletRecipients.length > 0" class="mt-8">
        <h1 class="heading">{{ $t('XeRecipients.MobileWalletText').value }}</h1>
        <SingleRecipient
          v-for="recipient in mobileWalletRecipients"
          :key="recipient.id"
          :recipient="recipient"
          :action-needed="actionNeeded"
          :select="viewRecipient"
          :countries="countries"
        />
      </div>

      <div v-if="businessRecipients && businessRecipients.length > 0">
        <h1 class="heading">
          {{ businessRecipientsCategoryTitle }}
        </h1>
        <SingleRecipient
          v-for="recipient in businessRecipients"
          :key="recipient.id"
          :recipient="recipient"
          :action-needed="actionNeeded"
          :select="viewRecipient"
          :countries="countries"
        />
      </div>

      <div v-if="services && services.length > 0" class="mt-8">
        <h1 class="heading">{{ $t('XeRecipients.ServicesText').value }}</h1>
        <SingleRecipient
          v-for="recipient in services"
          :key="recipient.id"
          :recipient="recipient"
          :action-needed="actionNeeded"
          :select="viewRecipient"
          :countries="countries"
        />
      </div>
    </template>
  </AppList>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api'

import SingleRecipient from '@galileo/components/XeRecipients/SingleRecipient'
import { AppList } from '@oen.web.vue2/ui'
import { storeToRefs } from 'pinia'

import {
  useI18nStore,
  useCountriesStore,
  useRecipientsStore,
  useAuthStore,
  useSendMoneyStore,
} from '@galileo/stores'

export default {
  name: 'XeRecipients',
  components: {
    SingleRecipient,
    AppList,
  },
  emits: [
    'loaded',
    'select',
    'viewRecipient',
    'editRecipient',
    'deleteRecipient',
    'sendMoneyRecipient',
  ],
  props: {
    country: {
      type: String,
      required: false,
      default: null,
    },
    currency: {
      type: String,
      required: false,
      default: null,
    },
    deliveryMethod: {
      type: String,
      required: false,
      default: null,
    },
    showLoader: {
      type: Boolean,
      required: false,
      default: true,
    },
    loadRecipients: {
      type: Boolean,
      default: false,
    },
    actionNeeded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const countriesStore = useCountriesStore()
    const recipientsStore = useRecipientsStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()

    const loading = ref(false)
    const countries = ref([])

    const isO2OTransfer = computed(() => sendMoneyStore.isO2OContract)

    const { isCorporateAccount } = storeToRefs(authStore)

    const businessRecipientsCategoryTitle = ref(
      isCorporateAccount.value
        ? $t('PageRecipients.CategoryOtherBusinesses').value
        : $t('ComponentAddRecipientTypeModal.ButtonBusiness').value
    )
    const otherRecipientsCategoryTitle = ref(
      isCorporateAccount.value
        ? $t('PageRecipients.CategoryOthersCorp').value
        : $t('XeRecipients.OthersText').value
    )
    const myAccountsCategoryTitle = ref(
      isCorporateAccount.value
        ? $t('PageRecipients.CategoryMyBusiness').value
        : $t('XeRecipients.MyselfText').value
    )

    const updateList = async () => {
      loading.value = true
      if (props.country && props.currency && !isO2OTransfer.value) {
        await getRecipientsBasedOnCountryAndCurrency()
      } else {
        await recipientsStore.getRecipients()
      }
      loading.value = false
    }

    const getRecipientsBasedOnCountryAndCurrency = async () => {
      await recipientsStore.getRecipientsByCountryAndCurrency({
        country: props.country,
        currency: props.currency,
        deliveryMethod: props.deliveryMethod,
      })
    }

    onBeforeMount(async () => {
      loading.value = true
      countries.value = countriesStore.getCountries
      loading.value = false
    })

    const recipients = computed(() => {
      const recipients = recipientsStore.recipients

      let result = null
      if (!isO2OTransfer.value) {
        // XeRecipients is used in SMF and in SMF deactivated recipients shouldn't be shown
        result = recipients.filter((recipient) => recipient && !recipient.deactivated)
      } else {
        const currency = sendMoneyStore.form.currencyTo

        // if is O2O contract we should display only the recipients with the selected currency
        // and exclude mobile wallet recipients
        result = recipients.filter(
          (recipient) =>
            recipient &&
            !recipient.deactivated &&
            recipient.currency === currency &&
            !recipient.isMobileWallet
        )
      }

      emit('loaded', result)
      return result
    })

    const ownRecipients = computed(() => {
      return recipients.value.filter((recipient) => {
        return recipient.isOwnAccount
      })
    })
    const businessRecipients = computed(() => {
      return recipients.value.filter((recipient) => {
        return recipient.isBusinessAccount && !recipient.isOwnAccount
      })
    })
    const mobileWalletRecipients = computed(() => {
      return recipients.value.filter((recipient) => {
        return recipient.isMobileWallet
      })
    })

    const allRecipients = computed(() => {
      return recipients.value.filter((recipient) => {
        return (
          !recipient.isOwnAccount &&
          !recipient.rawData.isGlobal &&
          !recipient.isBusinessAccount &&
          !recipient.isMobileWallet
        )
      })
    })

    const services = computed(() => {
      if (isCorporateAccount.value) {
        return []
      }
      return recipients.value.filter((recipient) => {
        return !recipient.isOwnAccount && recipient.rawData.isGlobal
      })
    })

    const viewRecipient = (recipient) => {
      emit('viewRecipient', recipient)
    }

    const editRecipient = (recipient) => {
      emit('editRecipient', recipient)
    }

    const deleteRecipient = (recipient) => {
      emit('deleteRecipient', recipient)
    }

    const sendMoneyRecipient = (recipient) => {
      emit('sendMoneyRecipient', recipient)
    }

    return {
      loading,
      services,
      countries,
      allRecipients,
      ownRecipients,
      businessRecipients,
      mobileWalletRecipients,
      viewRecipient,
      deleteRecipient,
      editRecipient,
      sendMoneyRecipient,
      $t,
      updateList,
      myAccountsCategoryTitle,
      otherRecipientsCategoryTitle,
      businessRecipientsCategoryTitle,
    }
  },
}
</script>
<style scoped>
.recipient-list {
  min-height: 150px;
}
.heading {
  @apply uppercase tracking-wider text-xs font-medium text-tertiary-text;
}
</style>
