<template>
  <InformationAppModal
    :value="value"
    :button-title="$t('NoMobileWalletsModal.ButtonLabel').value"
    icon="warningYellow"
    @confirm="editQuote"
  >
    <h1 class="title">
      {{ $t('NoMobileWalletsModal.Title').value }}
    </h1>
    <p>
      {{ $t('NoMobileWalletsModal.ParagraphText').value }}
    </p>
  </InformationAppModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

export default defineComponent({
  components: {
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()

    const editQuote = () => {
      router.push('/send-money')
    }

    return { $t, editQuote }
  },
})
</script>

<style scoped>
::v-deep .card-header {
  @apply mb-5 pt-0;
}
::v-deep .card.card--has-footer {
  @apply py-12;
}

::v-deep .card-content .card-content-block {
  @apply mb-6;

  p {
    @apply mt-4;
  }
}

::v-deep .modal-body p {
  @apply text-base;
}
</style>
