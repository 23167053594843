var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppInputWrapper',{class:{
    'input--no-border': !_vm.border,
    'input--no-arrow': !_vm.showArrow,
  },scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var input = ref.input;
  var wrapperLabel = ref.label;
return [_c('PrimeDropdown',_vm._g(_vm._b({staticClass:"input-input",attrs:{"options":_vm.getOptions(_vm.options),"option-label":_vm.selectedDisplayField,"option-value":_vm.selectedValueField,"data-key":_vm.dataKey,"aria-labelled-by":wrapperLabel.id,"show-clear":_vm.showClear,"disabled":_vm.disableDropdown},scopedSlots:_vm._u([{key:"value",fn:function(ref){
  var selectedValue = ref.value;
  var placeholder = ref.placeholder;
  var option = ref.option;
return [(option)?_c('div',{staticClass:"selection-option"},[_vm._t("selected",function(){return [_c('div',{staticClass:"selected-value"},[_c('span',[_vm._v(_vm._s(_vm.optionPreText))]),(option.value == 'OpenBanking' && _vm.isPayTo)?_c('span',{staticClass:"selected-value-text"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyAmount.PayToOpenBankingDropdownText').value)+" ")]):(option.value == 'OpenBanking')?_c('span',{staticClass:"selected-value-text"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyAmount.VoltOpenBankingDropdownText').value)+" ")]):_c('span',{staticClass:"selected-value-text"},[_vm._v(" "+_vm._s(option[_vm.selectedDisplayField || _vm.selectedValueField])+" ")])])]},{"value":selectedValue,"option":option})],2):_c('span',{staticClass:"select-placeholder"},[_vm._v(" "+_vm._s(placeholder)+" ")])]}},{key:"option",fn:function(ref){
  var option = ref.option;
return [_c('AppRipple',{class:{
          'dropdown-option-disabled': !option.isAvailable,
        }},[_c('div',{staticClass:"dropdown-option",on:{"click":function($event){return _vm.clickOption(option, $event)}}},[_c('div',{staticClass:"dropdown-option-container"},[_vm._t("option",function(){return [_c('div',{staticClass:"selected-value"},[_c('div',[_vm._v(_vm._s(_vm.optionPreText))]),_c('div',{staticClass:"selected-value-text"},[_vm._v(" "+_vm._s(option[_vm.selectedDisplayField || _vm.selectedValueField])+" ")])])]},{"option":option})],2)]),(option.separator)?_c('hr',{staticClass:"option-separator"}):_vm._e()])]}}],null,true),model:{value:(input.model),callback:function ($$v) {_vm.$set(input, "model", $$v)},expression:"input.model"}},'PrimeDropdown',input.attrs,false),input.listeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }