<template>
  <div>
    <AppCard>
      <template #header>
        <XeStepperCardHeader
          :title="$t('SendMoneyWalletDetails.Title').value"
          :emit-back="true"
          analytics-name="xe-send-money-select-mobile-wallet-providers-list"
        >
          <template #left>
            <AppBackButton class="back-button" name="Go Back" icon="<" @click="$emit('back')" />
          </template>
        </XeStepperCardHeader>
      </template>

      <AppMethodDropdown
        v-model="form.mobileWalletProvider.id"
        :label="$t('ComponentSendMoneyWalletDetails.WalletDropdownLabel').value"
        :options="mobileWalletProviders"
        analytics-name="send-money-mw-providers-selection-dropdown"
        :disabled="loading"
        :validation="providerValidation.mobileWalletProvider"
        selected-display-field="name"
        selected-value-field="id"
        option-pre-text=""
        @input="selectProvider($event)"
      >
        <template #option="{ option }">
          <div class="mw-provider-list-item">
            <AppFigure class="xe-figure" theme="gray">
              <img
                :alt="option.name"
                :src="option.image"
                @error="loadAlternativeLogo($event, option)"
              />
            </AppFigure>

            <div class="mw-item-description">
              <AppListItemTitle>
                <h1 class="font-medium">
                  {{ option.name }}
                </h1>
              </AppListItemTitle>

              <AppListItemCaption v-if="!option.isAvailable" class="error-text">
                {{ $t('SendMoneyWalletProviders.WalletLimitErrorText').value }}
                {{ option.amountMax | currency(currencyTo) }}
              </AppListItemCaption>
            </div>
          </div>
        </template>
      </AppMethodDropdown>

      <div v-if="!loading">
        <div v-for="field in formFields" :key="field.id">
          <AdditionalDetailsAppInputPhone
            v-if="field.id === 'mobileWalletPrefix' && !isAccountNumberFreeText"
            :key="field.id"
            :field="field"
            :form-fields="formFields"
            :is-recipient-step="isRecipientStep"
            @isValid="(val) => isValid(val)"
          />
        </div>

        <AppDynamicForm :key="formFields.length" :fields="formFields" />
      </div>
      <div v-else>
        <AppSpinnerBig :loading="loading" inline />
      </div>

      <AppButton
        :loading="isSubmitting"
        :disabled="
          formValidation.$anyInvalid ||
          !isMobileWalletNoValid ||
          providerValidation.$anyInvalid ||
          loading
        "
        @click="submit()"
        >{{ $t('AdditionalInformationModal.ContinueButton').value }}</AppButton
      >
    </AppCard>
    <MobileWalletValidationModal @recipient-edit="() => $emit('recipient-edit')" />
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  onBeforeMount,
  toRef,
  defineComponent,
} from '@vue/composition-api'

import { useValidation } from 'vue-composable'

import { useRouter } from '@galileo/composables/useRouter'

import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import AdditionalDetailsAppInputPhone from '@galileo/components/Views/SendMoney/AdditionalDetailsAppInputPhone/AdditionalDetailsAppInputPhone'

import { useSendMoneyAdditionalDetailsForm } from '@galileo/forms/SendMoneyAdditionalDetailsForm'

import MobileWalletValidationModal from '@galileo/components/Views/SendMoney/Modals/MobileWalletValidationModal'
import { currency } from '@galileo/utilities/filters'

import AppMethodDropdown from '@galileo/components/AppMethodDropdown/AppMethodDropdown'

import trimLeadingZero from '@galileo/composables/useMobileWallet/trimLeadingZero'

import { useAppStore, useAnalyticsStore, useI18nStore, useSendMoneyStore } from '@galileo/stores'

import {
  AppIcon,
  AppCard,
  AppFigure,
  AppListItemTitle,
  AppListItemCaption,
  AppBackButton,
  AppSpinnerBig,
  AppDynamicForm,
  AppInputDropdown,
  AppButton,
} from '@oen.web.vue2/ui'

export default defineComponent({
  components: {
    AppIcon,
    AppCard,
    AppFigure,
    AppListItemTitle,
    AppListItemCaption,
    AppBackButton,
    AppSpinnerBig,
    XeStepperCardHeader,
    AppDynamicForm,
    AppInputDropdown,
    AdditionalDetailsAppInputPhone,
    AppButton,
    MobileWalletValidationModal,
    AppMethodDropdown,
  },
  props: {
    providerId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const loading = ref(true)
    const isSubmitting = ref(false)
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    const isAccountNumberFreeText = ref(false)

    const { checkProvider, getCorrectedFields } = trimLeadingZero()

    const { setup, formFields, formValidation, resetFormFields } =
      useSendMoneyAdditionalDetailsForm(null)

    const fields = ref([])

    const isMobileWalletNoValid = ref(false)

    const form = reactive({
      mobileWalletProvider: null,
    })

    const setupFields = async () => {
      fields.value = sendMoneyStore.form.mobileWalletAdditionalFields

      await setup(fields.value)

      const walletAccountNo = formFields.value.find((field) => field.id === 'mobileWalletAccountNo')
      const walletPrefix = formFields.value.find((field) => field.id === 'mobileWalletPrefix')
      if (!walletPrefix) {
        isMobileWalletNoValid.value = true
      } else {
        walletPrefix.hidden = true
      }
      if (isAccountNumberFreeText.value) {
        isMobileWalletNoValid.value = true
      }

      // existing recipient flow
      const recipient = sendMoneyStore.getRecipient
      if (
        recipient &&
        form.mobileWalletProvider &&
        recipient.mobileWalletLocationId &&
        recipient.mobileWalletLocationId.toString() === form.mobileWalletProvider.id.toString() &&
        recipient.mobileWalletAccountNo
      ) {
        if (walletAccountNo) {
          walletAccountNo.value = recipient.mobileWalletAccountNo
        }
        if (walletPrefix) {
          walletPrefix.value = recipient.mobileWalletPrefix
        }
      }
    }

    onBeforeMount(async () => {
      await selectProvider(props.providerId)
    })

    const mobileWalletProviders = computed(() => {
      const providers = sendMoneyStore.mobileWalletProviders.providers
      for (const provider of providers) {
        if (isDisabledProvider(amountTo, provider.amountMin, provider.amountMax)) {
          provider.isAvailable = false
        } else {
          provider.isAvailable = true
        }
      }
      return providers
    })

    const activeStep = sendMoneyStore.activeStep
    const isRecipientStep = activeStep.name === 'Recipient'
    const amountTo = sendMoneyStore.getAmountTo(false)
    const currencyTo = sendMoneyStore.form.currencyTo
    const countryTo = sendMoneyStore.getCountryTo

    const isValid = (value) => {
      isMobileWalletNoValid.value = value
    }

    const invalidAmountError = ref(null)
    const providersList = sendMoneyStore.mobileWalletProviders.providers
    const providerValidation = useValidation({
      mobileWalletProvider: {
        $value: toRef(form, 'mobileWalletProvider'),
        required: {
          $validator(v) {
            return !invalidAmountError.value
          },
          $message: invalidAmountError,
        },
      },
    })

    let selectedProviderId = null
    const selectProvider = async (providerId) => {
      //if the user select the same provider do nothing
      if (providerId === selectedProviderId) {
        return
      }

      // According to Moises only Bancolombia A la Mano in Colombia is using free text at the moment - since it's not coming from the backend at the moment we agreed on hardcoding it
      if (providerId === 34398255) {
        isAccountNumberFreeText.value = true
      } else {
        isAccountNumberFreeText.value = false
      }

      resetFormFields()
      selectedProviderId = providerId
      loading.value = true
      const provider = providersList.find((item) => item.id === providerId)
      sendMoneyStore.setMobileWalletProvider(provider)
      form.mobileWalletProvider = Object.assign({}, provider)

      // if we use an existing recipient and then find out the amount is too big for the wallet show him an error
      if (amountTo > provider.amountMax && provider.amountMax > 0) {
        invalidAmountError.value = $t('SendMoneyWaletDetails.WalletLimitErrorText', {
          amount: currency(form.mobileWalletProvider.amountMax, currencyTo),
        }).value
        loading.value = false
        return
      } else {
        invalidAmountError.value = null
      }

      try {
        // // we need to call this in order to set the transaction method to mobile wallet
        await sendMoneyStore.calculate({ summary: true })
        //we retrieve the proper fields to fill for mobile wallets
        const nextPage = await sendMoneyStore.processMobileWalletOrder({
          replacePage: false,
        })

        await setupFields()
        sendSegmentEvents(SEGMENT_EVENTS.WALLET_DETAILS_STARTED)
      } catch (ex) {
        appStore.logException('Failed to select mobile provider', ex)
        appStore.messageBoxGenericError()
      }
      loading.value = false
    }

    const submit = async () => {
      isSubmitting.value = true
      let filledAdditionalDetailsFields = {}

      // creating the payload for the transfer call
      for (const formField of formFields.value) {
        const { id, value } = formField
        filledAdditionalDetailsFields[id] = filledAdditionalDetailsFields[id] || value
      }

      checkProvider(form.mobileWalletProvider)
      filledAdditionalDetailsFields = getCorrectedFields(filledAdditionalDetailsFields)

      // storing the payload in the store and calling for next step
      const nextPage = await sendMoneyStore.submitAdditionalDetails({
        filledAdditionalDetailsFields,
        isMobileWallet: true,
      })

      const additionalFields = sendMoneyStore.form.mobileWalletAdditionalFields

      const error = sendMoneyStore.mobileWalletWarning

      if (nextPage === '/send-money/summary/additional-details') {
        fields.value = additionalFields
        setup(fields.value)
      } else if (nextPage) {
        sendSegmentEvents(SEGMENT_EVENTS.WALLET_DETAILS_ADDED)
        router.replace(nextPage)
      } else if (additionalFields && !error.hasError) {
        // if the transfer API returned an error with a field (i.e. wallet account number didn't pass the backend validation)
        // show the error fields
        for (const additionalField of additionalFields) {
          const formField = formFields.value.find((field) => field.id === additionalField.id)
          if (formField && formField.validation.required) {
            formField.validation.required.$invalid = true
          }
        }
      }
      isSubmitting.value = false
    }

    const sendSegmentEvents = (segmentEvent) => {
      const provider = sendMoneyStore.mobileWalletProvider

      analyticsStore.track({
        event: segmentEvent,
        traits: {
          destinationCountry: countryTo,
          payoutCurrency: currencyTo,
          payoutAmount: amountTo,
          walletProvider: provider.name,
        },
      })
    }

    const isDisabledProvider = (amount, min, max) => {
      if (min === 0 && max === 0) {
        return false
      }
      return amount > max || amount < min
    }

    const loadAlternativeLogo = (event, provider) => {
      event.target.src = `https://qa04-public.riamoneytransfer.com/agent/logo/${provider.partnerId}?useMobile=true&agentToLocId=${provider.id}&deliveryMethod=34`
    }

    return {
      $t,
      loading,
      amountTo,
      currencyTo,
      mobileWalletProviders,
      formFields,
      isRecipientStep,
      form,
      isValid,
      selectProvider,
      formValidation,
      isMobileWalletNoValid,
      submit,
      isSubmitting,
      providerValidation,
      isDisabledProvider,
      loadAlternativeLogo,
      isAccountNumberFreeText,
    }
  },
})
</script>

<style scoped>
.error-text {
  @apply text-red-text;
}

.mw-provider-list-item {
  @apply flex flex-shrink-0 gap-4 items-center;
}

.mw-item-description {
  @apply flex flex-col;
}

::v-deep .selected-value-text {
  @apply text-primary-text;
}
</style>
