import { useFieldComponentHelper, useFieldPropsHelper } from '@galileo/composables/useFormHelpers'
import useFieldRules from '@galileo/composables/useFormHelpers/sendMoneyAdditionalDetailsFormFieldParser/useFieldRules'
import useFormFieldRules from '@galileo/composables/useFormHelpers/sendMoneyAdditionalDetailsFormFieldParser/useFormFieldRules'

import { DynamicFormField } from '@oen.web.vue2/ui'

import { reactive } from '@vue/composition-api'
import { useI18nStore, useAuthStore } from '@galileo/stores'

export default () => {
  const i18nStore = useI18nStore()
  const authStore = useAuthStore()

  const { fieldRules } = useFieldRules(i18nStore.$t)
  const { formFieldRules } = useFormFieldRules()

  const getFormField = async (field) => {
    //reassign field rules
    const fieldOverride = fieldRules[field.id]

    if (fieldOverride) {
      if (typeof fieldOverride === 'function') {
        field = Object.assign(field, fieldOverride(field))
      } else {
        field = Object.assign(field, fieldOverride)
      }
    }

    //generate dunamic form field
    let dynamicFormField = new DynamicFormField({
      field,
      value: '',
      hidden: false,
      disabled: false,
      props: useFieldPropsHelper(field),
      component: useFieldComponentHelper(field.type),
    })

    if (dynamicFormField.fieldDefinition.group === 'BASIC_ADDRESS_FIELDS') {
      for (const [key, value] of Object.entries(authStore.user.extraFields)) {
        if (dynamicFormField.id === key) {
          dynamicFormField.value = value
        }
      }
    }

    if (field.helperText) {
      dynamicFormField.props.helperText = field.helperText
    }
    if (field.hasAsyncValues && field.getAsyncValues) {
      dynamicFormField.props.options = await field.getAsyncValues()
    }

    //tiding up frmField
    let formFieldFunction = formFieldRules[dynamicFormField.id]
    if (formFieldFunction) {
      dynamicFormField = formFieldFunction(dynamicFormField)
    } else {
      formFieldFunction = formFieldRules['default']
      if (formFieldFunction) {
        const result = formFieldFunction(dynamicFormField)
        if (result) {
          dynamicFormField = result
        }
      }
    }

    const randomId = (Math.random() + 1).toString(36).substring(7)

    dynamicFormField.uniqueId = randomId
    return reactive(dynamicFormField)
  }

  return { getFormField }
}
