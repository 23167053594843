import { computed, reactive } from '@vue/composition-api'
import { helpers } from '@vuelidate/validators'
import { useValidation } from 'vue-composable'
import useFieldParser from '@galileo/composables/useFormHelpers/sendMoneyAdditionalDetailsFormFieldParser/useFieldParser'
import { useFieldValidation } from '@galileo/composables/useFormHelpers'
import { useDynamicForm } from '@galileo/forms/useDynamicForm'

const getFieldCustomValidation = (formField) => {
  const customValidation = {}
  if (formField.validation) {
    for (const fieldValidation of formField.validation) {
      if (formField.id === 'occupationFreeText' && fieldValidation.type === 'Regex') {
        customValidation.required = {
          $validator(v) {
            if (!formField.isVisible()) {
              return true
            }
            return helpers.req(v)
          },
          $message: 'Please enter an occupation',
        }
      }
      if (formField.id === 'idExpiryDate') {
        customValidation.required = {
          $validator(v) {
            if (!formField.isVisible()) {
              return true
            }
            return helpers.req(v)
          },
          $message: 'Please enter the expiry date',
        }
      }
      if (formField.id === 'licenceCardNumber') {
        customValidation.required = {
          $validator(v) {
            if (!formField.isRequired) {
              return true
            }
            return helpers.req(v)
          },
          $message: 'Please enter the licence card number',
        }
      }
      if (formField.id === 'passportFileNumber') {
        customValidation.required = {
          $validator(v) {
            if (!formField.isRequired) {
              return true
            }
            return helpers.req(v)
          },
          $message: 'Please enter the passport file number',
        }
      }
    }
  }

  return customValidation
}

const createFormFieldValidation = (formField, getFieldValidation) => {
  const validation = getFieldValidation(formField)
  const customValidation = getFieldCustomValidation(formField)

  return {
    ...validation,
    ...customValidation,
  }
}

export const useSendMoneyAdditionalDetailsForm = () => {
  const { formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()

  const { getFieldValidation } = useFieldValidation()
  const { getFormField } = useFieldParser()
  const groupsToUse = []

  const setup = async (fields) => {
    const fieldData = []
    const validationData = {}

    for (const field of fields) {
      const formField = await getFormField(field)

      validationData[formField.uniqueId] = createFormFieldValidation(formField, getFieldValidation)

      groupsToUse.push(field.group)
      fieldData.push(formField)
    }

    formValidation.value = useValidation(validationData)

    for (const formField of fieldData) {
      formField.validation = formValidation.value[formField.uniqueId]

      if (formField.parent) {
        formField.parent = fieldData.find((item) => item.id === formField.parent)
      }
    }

    let reoderedFields = []

    //reordering fields
    fieldData.forEach((elem) => {
      if (elem.id === 'mobileWalletAccountNo') {
        reoderedFields.splice(0, 0, elem)
      } else if (elem.id === 'mobileWalletPrefix') {
        reoderedFields.splice(1, 0, elem)
      } else if (elem.id === 'relationToBeneficiary') {
        reoderedFields.splice(2, 0, elem)
      } else if (elem.id === 'occupation') {
        reoderedFields.splice(3, 0, elem)
      } else if (elem.id === 'relationToBeneficiaryFreeText') {
        reoderedFields.splice(4, 0, elem)
      } else if (elem.id === 'sourceOfFunds') {
        reoderedFields.splice(5, 0, elem)
      }
    })

    formFields.value = fieldData
  }

  const resetFormFields = () => {
    formFields.value = []
  }

  return {
    setup,
    hasFormFields,
    isFormInvalid,
    formFields: computed(() => formFields.value),
    formValidation: computed(() => formValidation.value),
    groupsToUse,
    resetFormFields,
  }
}
