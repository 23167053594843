import { ref, reactive, toRef } from '@vue/composition-api'
import { integer, required } from '@vuelidate/validators'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useValidation } from 'vue-composable'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default (formFields, isRecipientStep) => {
  const { $t } = useI18nStore()
  const recipient = useSendMoneyStore().getRecipient

  const recipientPhoneNo = ref(null)

  const form = reactive({
    phone: '',
    countryCode: null,
  })

  const isMobilePrefixField = ref(false)
  const mobilePrefixHelperText = ref('')

  const setup = (phoneCountries) => {
    const findPhonePrefix = (country) => {
      return phoneCountries.value.find((item) => item.nameAbbr === country)
    }

    recipientPhoneNo.value = formFields.find((field) => field.id === 'ria_bene_mobile')

    const recipientCountryCode = findPhonePrefix(recipient.country)
    form.phone = recipientCountryCode.value
    form.countryCode = recipientCountryCode.valueKey
    validation.phone.$reset()
  }

  let countryIso = ''

  const onUpdateInputValues = (v) => {
    if (v) {
      countryIso = v.nameAbbr
    }
  }
  const onAreaCodeInputOption = (v) => {
    countryIso = v.nameAbbr
    form.countryCode = v.value
  }

  const inputPhone = () => {
    if (recipientPhoneNo.value && !isRecipientStep) {
      recipientPhoneNo.value.value = form.phone
    }
  }

  const validation = useValidation({
    phone: {
      $value: toRef(form, 'phone'),
      required: {
        $validator(v) {
          return required.$validator(v)
        },
        $message: $t('EditPhoneModal.ValidationPhoneRequired').value,
      },
      isValidPhoneNumber: {
        $validator(v) {
          if (v && !integer.$validator(v.replace('+', ''))) {
            return false
          }

          const parsePhoneNumberDetails = parsePhoneNumberFromString(v, countryIso)

          if (parsePhoneNumberDetails) {
            const isValid = parsePhoneNumberDetails.isValid()
            return isValid
          }
          return false
        },
        $message: $t('ComponentEditPhoneModal.ValidationPhoneValid').value,
      },
    },
  })

  return {
    setup,
    inputPhone,
    onUpdateInputValues,
    onAreaCodeInputOption,
    form,
    validation,
    mobilePrefixHelperText,
    isMobilePrefixField,
  }
}
