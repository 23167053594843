import { ref, reactive, toRef } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { integer, required } from '@vuelidate/validators'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { useI18nStore, useAuthStore } from '@galileo/stores'

export default (formFields, isRecipientStep) => {
  const { $t } = useI18nStore()
  const authStore = useAuthStore()
  const user = authStore.user
  const form = reactive({
    phone: user.mobilePhone.phoneNumberNoAreaCode ?? '',
    countryCode: null,
  })

  const isMobilePrefixField = ref(false)
  const mobilePrefixHelperText = ref('')
  let mobilePrefixField = null
  let mobilePhoneField = null

  const setup = () => {
    mobilePrefixField = formFields.find((field) => field.id === 'mobilePrefix')
    mobilePhoneField = formFields.find((field) => field.id === 'mobilePhone')

    if (!mobilePhoneField && !isRecipientStep) {
      isMobilePrefixField.value = true
      mobilePrefixHelperText.value = $t(
        'PageSendMoneyAdditionalDetails.mobilePrefixHelperText'
      ).value
    }
  }

  let countryIso = ''

  const onUpdateInputValues = (v) => {
    if (v) {
      countryIso = v.nameAbbr
    }
  }
  const onAreaCodeInputOption = (v) => {
    countryIso = v.nameAbbr
    form.countryCode = v.value
  }

  let phoneField = null

  const inputPhone = () => {
    if (phoneField) {
      phoneField.value = form.phone
    }
    if (mobilePrefixField) {
      mobilePrefixField.value = form.countryCode
    }
    if (mobilePhoneField) {
      mobilePhoneField.value = form.phone.replace(form.countryCode, '')
    }
  }

  const validation = useValidation({
    phone: {
      $value: toRef(form, 'phone'),
      required: {
        $validator(v) {
          // probably can be solved better but to ensure that we also include the validation of the form field
          return required.$validator(v)
        },
        $message: $t('EditPhoneModal.ValidationPhoneRequired').value,
      },
      isValidPhoneNumber: {
        $validator(v) {
          //if we are in this component we do have the prefix so we get rid of it for valdion purpose
          if (v && !integer.$validator(v.replace('+', ''))) {
            return false
          }

          const parsePhoneNumberDetails = parsePhoneNumberFromString(v, countryIso)
          if (parsePhoneNumberDetails) {
            return parsePhoneNumberDetails.isValid()
          }
          return false
        },
        $message: $t('ComponentEditPhoneModal.ValidationPhoneValid').value,
      },
    },
  })

  return {
    setup,
    inputPhone,
    onUpdateInputValues,
    onAreaCodeInputOption,
    form,
    validation,
    mobilePrefixHelperText,
    isMobilePrefixField,
  }
}
