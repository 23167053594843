import useMobileWalletAppInputPhone from '@galileo/composables/useAppInputPhone/useMobileWalletAppInputPhone'
import useRecipientAppInputPhone from '@galileo/composables/useAppInputPhone/useRecipientAppInputPhone'
import useUserAppInputPhone from '@galileo/composables/useAppInputPhone/useUserAppInputPhone'

import { ref } from '@vue/composition-api'

export default (formFields, isRecipientStep, field) => {
  let appInputPhoneVars = ref({ validation: null })

  const setup = (phoneCountries) => {
    switch (field.id) {
      case 'mobileWalletPrefix':
        appInputPhoneVars.value = useMobileWalletAppInputPhone(formFields, isRecipientStep)
        break
      case 'ria_bene_mobile':
        appInputPhoneVars.value = useRecipientAppInputPhone(formFields, isRecipientStep)
        break
      case 'mobilePrefix':
      case 'mobilePhone':
        appInputPhoneVars.value = useUserAppInputPhone(formFields, isRecipientStep)
        break
    }
    appInputPhoneVars.value.setup(phoneCountries)
  }

  return {
    setup,
    appInputPhoneVars,
  }
}
