<template>
  <AppInputPhone
    v-model="form.phone"
    :class="{ 'prefix-field-highlight': !form.countryCode }"
    analytics-name="edd-phone-input"
    :show-selected-flag="true"
    :options="phoneCountries"
    :validation="validation.phone"
    :label="phoneNumberLabel"
    :placeholder="$t('PageSendMoneyAdditionalDetails.phoneNumberInputPlaceholder').value"
    :number-disabled="isMobilePrefixField"
    :helper-text="mobilePrefixHelperText"
    @areaCodeInputOption="onAreaCodeInputOption"
    @updateInputValues="onUpdateInputValues"
    @input="inputPhone"
  />
</template>

<script>
import { AppInputPhone } from '@oen.web.vue2/ui'

import useAppInputPhone from '@galileo/composables/useAppInputPhone/useAppInputPhone'

import { watch, onBeforeMount, ref } from '@vue/composition-api'

import { useI18nStore, useResourcesStore } from '@galileo/stores'

export default {
  name: 'UserAppInputPhone',
  components: { AppInputPhone },
  props: {
    field: {
      type: Object,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    isRecipientStep: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['isValid'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()

    const phoneCountries = ref(resourcesStore.getPhoneCountries)

    const { setup, appInputPhoneVars } = useAppInputPhone(
      props.formFields,
      props.isRecipientStep,
      props.field
    )

    setup(phoneCountries)

    const phoneNumberLabel = ref('')

    onBeforeMount(() => {
      phoneNumberLabel.value = $t('PageSendMoneyAdditionalDetails.mobilePhoneNumberLabel').value
      emit('isValid', false)
    })

    watch(appInputPhoneVars.value, (value) => {
      emit('isValid', !value.validation.$anyInvalid)
    })

    return {
      $t,
      validation: appInputPhoneVars.value.validation,
      onAreaCodeInputOption: appInputPhoneVars.value.onAreaCodeInputOption,
      onUpdateInputValues: appInputPhoneVars.value.onUpdateInputValues,
      phoneNumberLabel,
      phoneCountries,
      inputPhone: appInputPhoneVars.value.inputPhone,
      mobilePrefixHelperText: appInputPhoneVars.value.mobilePrefixHelperText,
      isMobilePrefixField: appInputPhoneVars.value.isMobilePrefixField,
      form: appInputPhoneVars.value.form,
    }
  },
}
</script>

<style scoped>
.prefix-field-highlight {
  ::v-deep .prime-dropdown.p-inputwrapper {
    border-color: rgba(0, 108, 224, 1);
    box-shadow: inset 0 0 0 1px rgb(0 108 224);
  }
  ::v-deep label {
    @apply font-medium;
  }
  ::v-deep .input-helper,
  .input-validation {
    @apply font-normal;
  }
}
</style>
