import { reactive, toRef, ref } from '@vue/composition-api'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { integer, required } from '@vuelidate/validators'
import { useValidation } from 'vue-composable'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default (formFields, isRecipientStep) => {
  const { $t } = useI18nStore()
  const sendMoneyStore = useSendMoneyStore()

  const form = reactive({
    phone: '',
    countryCode: null,
  })

  const isMobilePrefixField = ref(false)
  const mobilePrefixHelperText = ref('')

  let mobileWalletPrefixField = null
  let mobileWalletAccountNoField = null
  let mobileWalletDefaultPrefix = null

  const setup = (phoneCountries) => {
    const countryTo = sendMoneyStore.getCountryTo

    mobileWalletDefaultPrefix = phoneCountries.value.find((item) => item.nameAbbr === countryTo)
    mobileWalletPrefixField = formFields.find((field) => field.id === 'mobileWalletPrefix')
    mobileWalletAccountNoField = formFields.find((field) => field.id === 'mobileWalletAccountNo')

    let mobileWalletAccountNo = mobileWalletDefaultPrefix.value

    if (mobileWalletPrefixField?.value) {
      mobileWalletAccountNo = mobileWalletPrefixField.value.replace(' ', '')
    }
    if (mobileWalletAccountNoField?.value) {
      mobileWalletAccountNo += mobileWalletAccountNoField.value.replace(' ', '')
    }
    if (isRecipientStep) {
      form.phone = mobileWalletAccountNo
      validation.phone.$reset()
    }
  }

  let countryIso = ''

  const onUpdateInputValues = (v) => {
    if (v) {
      countryIso = v.nameAbbr
    }
  }
  const onAreaCodeInputOption = (v) => {
    countryIso = v.nameAbbr
    form.countryCode = v.value
  }

  const inputPhone = () => {
    if (mobileWalletPrefixField) {
      mobileWalletPrefixField.value = form.countryCode
      mobileWalletPrefixField.hidden = true
    }
    if (mobileWalletAccountNoField) {
      //remove the prefix from the phone number
      mobileWalletAccountNoField.value = form.phone.replace(form.countryCode, '')
      if (mobileWalletPrefixField) {
        mobileWalletAccountNoField.hidden = true
      }
    }
  }

  const validation = useValidation({
    phone: {
      $value: toRef(form, 'phone'),
      required: {
        $validator(v) {
          // probably can be solved better but to ensure that we also include the validation of the form field
          if (mobileWalletPrefixField?.validation?.required?.$invalid) {
            return false
          }
          if (mobileWalletAccountNoField?.validation?.required?.$invalid) {
            return false
          }

          return required.$validator(v)
        },
        $message: $t('EditPhoneModal.ValidationPhoneRequired').value,
      },
      isValidPhoneNumber: {
        $validator(v) {
          if (mobileWalletAccountNoField?.value) {
            // for other countries we don't apply the validation since the phone number can look different there (request from Moises)
            if (mobileWalletDefaultPrefix && form.countryCode !== mobileWalletDefaultPrefix.value) {
              return true
            }

            //we get the regex for the wallet number field
            let regex = mobileWalletAccountNoField.fieldDefinition.validations.find(
              (validation) => validation.type === 'Regex'
            )
            // we adjust the regex value so it accepts only numbers and after that the given regex
            regex.value = regex.value.replace('.', '[0-9]')
            // we are validating the full number
            const numberNoCountryCode = v.replace('+', '')

            const walletNumberRegex = new RegExp(regex.value)
            return walletNumberRegex.test(numberNoCountryCode)
          } else {
            if (v && !integer.$validator(v.replace('+', ''))) {
              return false
            }

            const parsePhoneNumberDetails = parsePhoneNumberFromString(v, countryIso)
            if (parsePhoneNumberDetails) {
              return parsePhoneNumberDetails.isValid()
            }
            return false
          }
        },
        $message: $t('ComponentEditPhoneModal.ValidationPhoneValid').value,
      },
    },
  })

  return {
    setup,
    inputPhone,
    onUpdateInputValues,
    onAreaCodeInputOption,
    form,
    validation,
    mobilePrefixHelperText,
    isMobilePrefixField,
  }
}
