<template>
  <div>
    <AppSpinnerBig v-if="loading" :loading="loading" />

    <AppCard v-if="showMobileWalletProvidersList">
      <template #header>
        <XeStepperCardHeader
          :title="$t('SendMoneyWalletProviders.Title').value"
          :emit-back="true"
          analytics-name="xe-send-money-select-mobile-wallet-providers-list"
        >
          <template #left>
            <AppBackButton class="back-button" name="Go Back" icon="<" @click="$emit('back')" />
          </template>
        </XeStepperCardHeader>
      </template>

      <AppList v-if="!loading">
        <template>
          <div v-if="mobileWalletProviders" class="mt-8">
            <XeListItem
              v-for="provider in mobileWalletProviders"
              :key="provider.id"
              :text="provider.name"
              :disabled="isDisabledProvider(amountTo, provider.amountMin, provider.amountMax)"
              @select="selectProvider(provider, true)"
            >
              <template #figure-icon>
                <img
                  v-if="provider.image"
                  :alt="provider.name"
                  :src="provider.image"
                  @error="loadAlternativeLogo($event, provider)"
                />
              </template>

              <template #error>
                <AppListItemCaption
                  v-if="isDisabledProvider(amountTo, provider.amountMin, provider.amountMax)"
                  class="error-text"
                >
                  {{ $t('SendMoneyWalletProviders.WalletLimitErrorText').value }}
                  {{ provider.amountMax | currency(currencyTo) }}
                </AppListItemCaption>
              </template>
            </XeListItem>
          </div>
        </template>
      </AppList>
    </AppCard>

    <NoMobileWalletsModal v-model="showNoWalletsAvailableModal" />

    <SendMoneyWalletDetails
      v-if="showAddMobileWalletDetails && !showMobileWalletProvidersList"
      :provider-id="selectedProviderId"
      @back="() => goBack()"
      @recipient-edit="() => $emit('recipient-edit')"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { ref, computed, onBeforeMount } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import { formatCurrency } from '@galileo/composables/useCurrency'

import NoMobileWalletsModal from '@galileo/components/Views/SendMoney/Modals/NoMobileWalletsModal'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import XeListItem from '@galileo/components/XeListItem/XeListItem'
import SendMoneyWalletDetails from '@galileo/components/Views/SendMoney/SendMoneyWalletDetails'

import {
  AppIcon,
  AppCard,
  AppFigure,
  AppList,
  AppListItemCaption,
  AppBackButton,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

import { useI18nStore, useDeviceStore, useSendMoneyStore, useAppStore } from '@galileo/stores'

export default defineComponent({
  components: {
    AppIcon,
    AppCard,
    AppFigure,
    AppList,
    XeListItem,
    AppListItemCaption,
    AppBackButton,
    AppSpinnerBig,
    XeStepperCardHeader,
    NoMobileWalletsModal,
    SendMoneyWalletDetails,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const deviceStore = useDeviceStore()
    const appStore = useAppStore()
    const router = useRouter()
    const loading = ref(false)
    const showNoWalletsAvailableModal = ref(false)
    const showMobileWalletProvidersList = ref(false)
    const showAddMobileWalletDetails = ref(false)
    const selectedProviderId = ref(null)
    const sendMoneyStore = useSendMoneyStore()

    const recipient = sendMoneyStore.getRecipient
    const amountTo = sendMoneyStore.getAmountTo(false)
    const currencyTo = sendMoneyStore.form.currencyTo
    const locale = deviceStore.getLocale
    const mobileWalletProviders = computed(() => {
      return sendMoneyStore.mobileWalletProviders.providers
    })

    onBeforeMount(async () => {
      loading.value = true
      await sendMoneyStore.setMobileWalletProviders({
        country: sendMoneyStore.getCountryTo,
        currency: currencyTo,
        amount: amountTo,
      })

      try {
        const bodyElement = document.getElementsByTagName('body')[0]

        if (bodyElement.classList.contains('overflow-hidden')) {
          bodyElement.classList.remove('overflow-hidden')
        }
      } catch (ex) {
        appStore.logInfo('Failed to remove overflow')
      }

      const isAllProvidersExceeded = mobileWalletProviders.value.every((provider) => {
        return isDisabledProvider(amountTo, provider.amountMin, provider.amountMax)
      })

      if (mobileWalletProviders.value.length === 0 || isAllProvidersExceeded) {
        showNoWalletsAvailableModal.value = true
        loading.value = false
        return
      } else if (
        recipient &&
        recipient.mobileWalletCorrespondentId &&
        recipient.mobileWalletLocationId
      ) {
        // if recipient already has a wallet don't show the provider list and show directly the wallet details
        const currentProvider = mobileWalletProviders.value.find(
          (provider) => provider.id.toString() === recipient.mobileWalletLocationId.toString()
        )
        if (currentProvider) {
          loading.value = false
          await selectProvider(currentProvider, false)
          return
        }
      }

      showMobileWalletProvidersList.value = true
      loading.value = false
    })

    const selectProvider = async (provider, emitEvent) => {
      showMobileWalletProvidersList.value = false
      showAddMobileWalletDetails.value = true
      selectedProviderId.value = provider.id

      if (emitEvent) {
        emit('selectProvider', provider)
      }
    }

    const isDisabledProvider = (amount, min, max) => {
      if (min === 0 && max === 0) {
        return false
      }
      return amount > max || amount < min
    }

    const editQuote = () => {
      router.push('/send-money')
      showNoWalletsAvailableModal.value = false
    }

    const loadAlternativeLogo = (event, provider) => {
      event.target.src = `https://qa04-public.riamoneytransfer.com/agent/logo/${provider.partnerId}?useMobile=true&agentToLocId=${provider.id}&deliveryMethod=34`
    }

    const goBack = () => {
      if (recipient && recipient.mobileWalletCorrespondentId && recipient.mobileWalletLocationId) {
        emit('back')
      } else {
        showMobileWalletProvidersList.value = true
      }
    }

    return {
      $t,
      loading,
      formatCurrency,
      locale,
      currencyTo,
      amountTo,
      mobileWalletProviders,
      showNoWalletsAvailableModal,
      isDisabledProvider,
      selectProvider,
      editQuote,
      loadAlternativeLogo,
      showMobileWalletProvidersList,
      showAddMobileWalletDetails,
      selectedProviderId,
      goBack,
    }
  },
})
</script>

<style scoped>
.error-text {
  @apply text-red-text;
}
</style>
